
.order-container {
  display: flex;
}

.directory-links {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: -.5rem 0 1rem 0;
}

.directory-links a {
  color: #999;
  font-family: $condensed-font sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.directory-links a:hover {
  color: $color-primary;
  text-decoration: none;
}

.directory-links span {
  margin: 0 .3rem;
  color: #999;
}


.dropdown-item {
  padding: 1rem;
}

.dropdown-item:active {
  background-color: $color-light-1;
}

/* APARTMENT COMPLEX */

/* SLIDESHOW  */

.img1,
.img2 {
  flex: 1 1 30%;
  padding: 0 0 0 .5rem;
}

.img-grid {
  padding: 2.5px;
  flex: 1 1 50%;
}

.img2 {
  padding-top: .5rem;
}

.hero-0 {
  height: 100%;
  flex: 0 0 100%;
  object-fit: cover;
}

.apt-max-width {
  display: flex;
  flex-flow: row nowrap;
}

.apt-container {
  display: flex;
  margin: 2rem 0 0 0;
  flex: 0 0 70%;
}

.text-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  color: $color-white;
  position: absolute;
  overflow: hidden;
  width: 100%;
  bottom: 0;
  height: 19%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;
}

.hero-apt-overlay {
  font-family: $condensed-font;
  font-size: 3rem;
  margin: 0 6rem 0 0;
}

.location {
  font-family: $condensed-font;
  font-size: 2rem;
  margin: 0 6rem 0 0;
}

.apt-container-content {
  display: flex;
  margin: 0;
}


.graph-section {
  display: flex;
  flex-flow: row nowrap;
}

.graph-section a:hover {
  text-decoration: none;
}

.graph-section a:hover {
  text-decoration: none;
}

.graph-section a:active {
  text-decoration: none;
  background-color: $color-primary;
}

.graph-section h2 {
  font-size: 2.8rem;
  text-transform: uppercase;
}


