/*  DIRECTORY BUSINESS PAGE */
.news-container h6 {
  font-weight: 300;
}

.directory-info {
  display: flex;
  justify-content: space-between;
  flex-flow: row;

  @include respond(phone) {
    margin-bottom: 1rem;
  }
}

.info-wrapper {
  @include respond(phone-min) {
    margin-top: 1rem;
    padding-right: 5.5rem;
  }

  h4 {
    line-height: 1.8rem;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.4;
  }
}

.phone {
  font-size: 1.5rem;
  font-family: $condensed-font;
  color: $color-darker-grey;
  display: flex;
  align-items: center;
}

.fa-phone {
  margin-top: -2px;
}

.socialmedia-d {
  width: 22%;
  display: flex;
  justify-content: space-between;
  color: $color-light-2;
}

.socialmedia-d a {
  color: $color-light-2;
}

.socialmedia-d a:hover {
  color: $color-light-2;
}

.social-media-pr-article {
  display: flex;
  flex-basis: 20rem;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.social-media-pr-article a:hover {
  color: $color-light-2;
}

.fa-map-marker {
  padding-right: 1rem;
}

#googleMap {
  @include respond(phone-min) {
    margin-bottom: 3rem;
  }
}

.pr-contacts {
  margin-top: 3rem;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 3rem 0;


  h3 {
    display: inline-block;
    font-style: $condensed-font;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    border-bottom: 3px solid $color-primary;
  }
  p {
    font-size: 1.6rem;
    margin-bottom: .5rem;
  }
}