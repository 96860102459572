.ojline {
  width: 100%;
  border-bottom: 3px solid $color-primary;

  &--right-margin {
    margin-right: 5rem;
  }
}

.latest__ojline {
  &--apartments {
    border-bottom: 2px solid #D67C3A;
    width: 16rem !important;
  }
}

.ojlinecreateaccount {
  border-bottom: 3px solid $color-primary;
  width: 100%;
}

.ojline-form {
  border-bottom: 3px solid $color-primary;
  width: 98.9%;
}

.ojline-apt {
  width: 100%;
  border-bottom: 2px solid $color-primary;
}

.ojline-nl1 {
  border-bottom: 2px solid $color-primary;
  width: 26.5rem;
  padding-bottom: 3px;
  margin: 0 0 1rem 0;
}

.ojunderline-head {
  border-bottom: 1px solid #646464;
  margin-top: .5rem;
}

.service-line {
  border-bottom: .3rem solid $color-primary;
  margin-top: -5px;
}

.apt-line {
  border-bottom: 1px solid $color-light-2;
  width: 90%;
  align-self: center;
  margin: -.8rem 0 .3rem 0;
}

.apt-line2 {
  border-bottom: 1px solid $color-light-2;
  width: 100%;
  align-self: center;
  margin: 1rem 0 2.5rem;
}

.ojunderline-acc {

}