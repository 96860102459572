/* ACCOUNT SERVICES  */
.coming-soon {
  font-size: 1.5rem;
  font-style: italic;
}

.col-md-4.dashboard {
  padding-left: 1rem;
  margin-left: -.8rem;
  padding-right: 0;
}

.account-services {
  max-width: 144rem;
  padding: 0;
}

.badge-info {
  color: $color-primary !important;
  background-color: $color-white;
  font-size: 1.1rem;
  padding: .8rem;
  margin-left: 0px;
  margin-top: 1rem;
}

.rssitem2 a:hover {
  text-decoration: none;
}

.fa-user {
  color: #acacacc2;
  margin-top: 1.2rem;
}

.head-row {
  margin: 4rem auto;
}


.manage-account {
  margin: 4.3rem 0;
  @media screen and (max-width: 768px) {
    margin-top: 3rem;
  }
  p {
      font-size: 2rem;
  }

  &__header {
    border-bottom: 1px solid #d6d6d6;
    font-size: 3rem;
    @include respond (phone) {
      margin-bottom: 2rem;
    }
    @include respond(phone-min) {
      font-size: 5rem;
      margin: 3rem 0 3rem 0;
      padding-bottom: .8rem;
      color: $color-dark-text;
      font-weight: 400;
    }
  }

  &__manage-subject {
    font-size: 5rem;
  }
}

  /* RSS ITEMS STYLES */
  .rssitem {
    @include respond (phone) {
      padding: 0;

    }
    p {
        font-size: 2rem;
        margin: 0;
        font-family: $condensed-font;
        text-transform: uppercase;
        font-size: $default-font-size;
        color: $color-dark-text;
    }
    h1 {
        font-size: 4rem;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        color: $color-dark-text;
        @include respond(phone) {
          line-height: 1;
        }
    }
 
    span {
      color: $color-primary;
    }

    a {
      @media screen and (max-width:768px) {
        font-size: 2.8rem;
      }
    } 

    &__greyline {
      margin: 2rem 0;
      border-bottom: 1px solid $color-light-2;
    
      @include respond (phone-min) {
        margin: 2.5rem auto 3rem auto;
      }
    }
  }

.dashbox {
  height: 35.5rem;
  border: $color-light-2 1px solid;
}

.dhead {
  background-color: $color-light-1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  text-transform: uppercase;
}

.dhead h3 {
  font-family: $condensed-font;
  color: $color-dark-text;
  font-size: 3rem;
}

.dbox-body {
  display: flex;
}

.post-circle {
  width: 20rem;
  height: 20rem;
  border-radius: 100%;
  border: $color-primary 3px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 0 auto;
}

.postamount {
  font-size: 7rem;
}

.totalposts {
  display: flex;
  justify-content: flex-end;
  margin: 3rem 2rem 0 0;
}

.userdata {
  color: #999;
  font-size: 1.4rem;
}

.userdata span {
  color: $color-primary;
}

    /* MY ACCOUNT SERVICES  */
    .account {
      font-size: 3.6rem;
      margin-top: 4rem;
  }




