*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

::selection {
    background-color: #2E3137;
    color: white;
}

.fa,
.fa-caret-down {
    font-weight: 300 !important;
}

html {
    /*this defines what 1 rem is  */
    font-size: 64.5%;
    
    @include respond(phone) {
        font-size: 59%;
    }

    @include respond(tab-land) {
        font-size: 60%;
    }
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: $default-font-size;
    font-weight: 300;
    box-sizing: border-box;
}

a {
    color: $color-dark-text;
    text-decoration: none;
}

p a, p em a {
    border-bottom: solid #dfdfdf 1px !important;
}

a:link {
    text-decoration: none;
}

a:hover {
    color: $color-medium-grey;
}

.form-control {
    font-size: 1.6rem;
}

p { 
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 0;
    font-size: 1.8rem;

    @include respond(images3) {
        font-size: 1.5rem;
      }

}

th { 
    font-weight: 300 !important;
    font-family: $default-font;
    color: $color-dark-text;
}


select.form-control:not([size]) {
    height: calc(2.25rem + 23px) !important;
    font-size: $default-font-size !important;
    font-family: $default-font !important;
    color: $color-medium-grey !important;
}

.input-group-text {
    padding: 1.375rem 1.75rem;
    font-size: 1.6rem;
}

.form-group.row {
    margin-right:-25px !important;
    margin-left: -25px !important;
}

.form-control {
    font-weight: 300 !important;
}

.form-group {
    margin-bottom: 2rem !important;
}

textarea {
    resize: none;
  }

  .row-margin-reset {
      margin-left: 0 !important;
      margin-right: 0 !important;
  }

 .validation-summary-errors {
    ul {
        @include respond (tab-land-min) {
            display: flex;
            margin: -1rem 0 2rem 2rem;       
        }

        li {
            @include respond(tab-land) {
                margin-left: 5rem;
            }  
            @include respond (tab-land-min) {
                padding-right: 4rem;
            }
        }
    }
 }
