.mapbox-map.map-container #mapbox-marker-image {
    cursor: pointer;
    background-image: url(/images/map-marker-orange.png);
    background-repeat: no-repeat;
    height: 45px;
    width: 35px;
}

.mapboxgl-popup {
    p {
        font-size: 1.4rem;
        max-width: 100% !important;
    }
    .mapboxgl-popup-close-button {
        width: 10%;
    }
    .mapbox-infowindow {
        max-width: 275px;
        overflow: hidden;
        font-weight: 300;
        font-size: 13px;
        color: #464646;
        margin-left: 15px;
        padding-right: 20px;

        h5 {
            font-weight: bold;
        }
    }
}