.wrapper {
    margin: 4rem auto;
    padding: 0 1rem;

    @include respond (tab-port-min) {
        padding: 0 1.5rem;
    }

    @include respond (tab-land-min) {
        max-width: 144rem;
    }

    &--top {
        margin: 9rem auto 3rem auto;
        position: relative;
        
        @include respond (tab-land-min) {
            margin: 9rem auto 3rem auto;
        }
    }

    &--constraint {
        @include respond (tab-land-min) {
            max-width: 110rem;
        }
    }

    &--less-margin {
        margin: 4rem auto 2rem auto;
    }

    &--no-margin {
        margin: 0 auto 0 auto;
    }
}

.flex-container {
    display: flex;

    &--column {
        flex-flow: column wrap;
        align-items: flex-start;
    }

    &--bottom-margin-more {
        margin-bottom: 2rem;
        @include respond (tab-port-min) {
            margin-bottom: 4rem;
        }
    }

    &--justify-center {
        justify-content: center;
    }

    &--gutters {
        @include respond (phone-min) {
            & > div {
                margin: 1rem;
            }
        }
    } 
}