.login {
    display: flex;

    @media (min-width: 576px) {
        max-width: 765px;
        margin: 1.75rem auto;
    }

    &__content {
        border-radius: 0;
        border: none;
    }

    &__body {
        padding: 20px 10px;

        &--login {
            padding: 30px 10px; 
        }

        @media (min-width: 992px) {
            flex: 0 1 auto;
            padding: 30px 106px;
        }

        .h1 {
            color: $color-dark-text;
            padding-bottom: 20px;

            @media (min-width: 768px) {
                font-size: 36px;
            }

            @media (min-width: 992px) {
                font-size: 50px;
            }
        }
    }

    &__head {
        background-color: $color-dark-text;
        align-items: center;
        padding: .5rem 4rem;
        border-radius: 0;

        img {
            margin: 0 auto;
        }
    }

    &__btn {
        max-width: 590px;
        height: 88px;
        background-color: #F6F6F6;
        display: flex;
        justify-items: center;
        align-items: center;
        flex-flow: row nowrap;
        cursor: pointer;
        transition: ease-in 300ms background-color;

        &:hover {
            background-color: #dcdcdc;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        img {
            flex: 0 0 auto;
            padding: 0 10% 0 20px;

            @media screen and (max-width: 576px) {
                height: 40px;
                padding: 0 6% 0 20px;
            }
        }
        font-size: 28px;
        margin-top: 10px;
        font-weight: 500;

        @media screen and (max-width: 576px) {
            font-size: 22px;
        }
    }

    &__close {
        margin: 0 !important; //clear defaults
        padding: 0 !important; //clear defaults
        opacity: 1;
        color: #fff;

        &::focus {
            outline: none;
        }

        &:hover {
            color: #fff;
        }
    }

    &__footer {
        height: 109px;
        background-color: #F6F6F6;
        border-top: none;
        justify-content: center;

        @media (min-width: 576px) {
            margin-top: 10px;
        }

        a {
            text-align: center;
            font-size: 36px;
            text-decoration: none;
            color: $color-dark-text;

            @media screen and (max-width: 576px) {
                font-size: 28px;
            }

            span {
                color: $color-primary;
                font-size: 36px;
                @media screen and (max-width: 576px) {
                    font-size: 28px;
                }
            }
        }
    }
}

// REGISTER 
.loginform {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &--email {
        padding: 0 10px;
        transform: scale(0);
        position: absolute;
        transition: 300ms ease-in all;
        opacity: 0;
    }

    &__show {
        transform: scale(1);
        display: flex;
    }

    &__input {
        flex: 0 0 100%;
        margin-bottom: 20px;

        @media screen and (min-width: 576px) {
            flex: 0 0 50%;
        }

        &--pr1 {
            @media screen and (min-width: 576px) {
                padding-right: 10px;
            }
        }

        &--pl1 {
            @media screen and (min-width: 576px) {
                padding-left: 10px;
            }
        }

        input {
            height: 50px;
            border-radius: 0;

            &.clear {
                margin-bottom: 0;
            }

            &:focus,
            &:active {
                outline: none;
                border-color: $color-primary;
                box-shadow: 0 0 0 0;
            }
        }
    }

    &__btn {
        display: block;
        width: 100%;
        text-align: center;
        background-color: $color-primary;
        color: #fff;
        font-weight: 300;
        text-transform: uppercase;
        padding: 15px 0;
        cursor: pointer;
        margin: 30px 0 20px;

        &--email {
            margin: 0 0 20px;
        }
    }

    &__links {
        display: flex;
        justify-content: space-around;
        width: 100%;

        a {
            color: $color-primary;
            text-decoration: none;
        }
    }
}

.showform {
transform: scale(1);
position: relative;
transform-origin: top left;
opacity: 1;
}