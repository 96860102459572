.Newsletter {
    background-color: $color-light-1;
    height: 34rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;

    p {
        font-size: 1.6rem;
        color: $color-darker-grey;
        margin-top: -1rem;
        margin-bottom: 3rem;

        @include respond(images3) {
            margin-top: 0;
            margin-bottom: 1rem;
            text-align: center;
        }

        @include respond (phone) {
            margin-top: 0;
        }
    }

    .newsletter-logo {
        height: 8rem;
        margin-bottom: 1rem;
    }
}

.newsletter-header {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;

    h2 {
        font-family: $condensed-font;
        font-weight: 300;
        font-size: 6.5rem;
        color: $color-dark-text;

        @include respond(phone) {
            font-size: 2.8rem;
        }

        @include respond(images3) {
            font-size: 2.8rem;
            line-height: 1;
        }
    }

    img {
        margin: 0 2rem 0 0;
        height: 4rem;
        width: 4.6rem;

        @include respond(images3) {
            height: 2.5rem;
            width: 2.5rem;
            margin: -1rem .8rem 0 0;
            text-align: center;
        }
    }
}

.newsletter-form {
    display: flex;
    align-items: center;
    justify-content: center;
  
    &__btn {
        background-color: $color-primary;
        height: 100%;
        border: solid 1px $color-primary;
        border-radius: 1px;
        font-size: 1.5rem;
        color: #fff;
        font-family: $condensed-font;
        width: 11rem;
        height: 5.6rem;
        cursor: pointer;

        @include respond(phone) {
            width: 8rem;
        }
    }

    input {
        width: 35rem;
        height: 5.6rem;
        border: solid 1px $color-light-2;
        border-radius: 0;
        color: #65676B;
        font-weight: 300;
     
        @include respond(images3) {
            width: 24rem;
        }

        @include respond(phone) {
            width: 14em;
        }
    }
}

footer {
    max-width: 100%;
    background-color: $color-dark-text;
    margin: 0 auto;

    h2 {
        color: $color-white;
        font-family: 'Open Sans', sans-serif;
        padding: 2rem 0 0 0;
        line-height: 1.6;
        font-size: 4rem;
        text-transform: uppercase;

        @include respond(phone) {
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        @include respond(images3) {
            font-size: 3.4rem;
            line-height: 1;
        }

    }
}

.footer-nav {
    text-align: center;
    padding: 2rem 0 0 0;
    font-family: $condensed-font;

    a {
        color: #fefefe;
        text-decoration: none;
    }

    &__items {
        border-bottom: 1px solid rgba(106, 107, 109, 0.26);
        padding-bottom: 2rem;
        a {
            padding: 1rem 2rem;    
            &:hover {
                color: rgb(170, 170, 170);
            }
        }
        @include respond(phone) {
            display: none !important;
        }
    }

    li {
        list-style-type: none;
        text-align: center;
        display: inline;
        list-style-type: none;
        font-family: $condensed-font;

        &:not(:last-child) {
            border-right: solid 1px rgba(106, 107, 109, 0.26); 
        }
    }

    .mfb-white-logo {
        margin: 2rem auto;
        padding: 1.5rem 0;
        height: 8.4rem;
        width: 8.4rem;

        @include respond(phone) {
            height: 6.4rem;
            width: 6.4rem;
        }
    }

    .disclaimer {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.3rem;
        max-width: 98.8rem;
        color: #65676B;
        margin: 0 auto;
        padding: 3rem 2rem;
    }

    &__social-media {
        a {
            padding: 1rem;
        }

        img {
            height: 5.5rem;
            width: 5.5rem;
        }  
    }

    &__bottom {
        color: $color-white;
        padding-top: 2rem;
        font-size: 1.4rem;

        .reserved-rights {
            @include respond(phone) {
                margin: 1rem 0;
            }
            p {
                font-size: 1.4rem;
                color: #dfdfdf;    
                @include respond(phone) {
                    font-size: 1.4rem;
                }
            }
        }

        li {
            list-style-type: none;
            text-align: center;
            display: inline;
            list-style-type: none;
            font-family: $condensed-font;
            &:not(:last-child) {
                border-right: solid 1px rgba(106, 107, 109, 1); 
            }
            a {
                padding: 1rem 1rem;    
                color: #dfdfdf;    
                &:hover {
                    color: rgb(124, 116, 116);         
                }
            }
        }
    }
}

.f-line2 {
    width: 100%;
    height: 1px;
    background: rgba(106, 107, 109, 0.26);
}

#btnlogo {
    width: 3.5rem;
    height: 3.5rem;
    margin-top: -1rem;

    @media screen and(max-width: 768px) {
    margin-top: 0rem;
     float: right;
    }

}
