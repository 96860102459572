/* UNIFY BUTTON STYLES IN BEM */

//INLINE BLOCK BTN 
.btn {
    display: inline-block;
    border-radius: 0;
    cursor: pointer;
    font-size: 1.6rem;

    &--ghost-btn {
        padding: 2rem 6rem;
        max-width: 100%;
        border: 1px solid #fff;
        margin-top: 4rem;
        background-color: transparent;
        text-transform: uppercase;
        margin-left: 30%;

        &:hover {
            background-color: rgba(0,0,0,.247);
        }
        span {
            font-size: 2.8rem;
            color: #fff;
            margin: auto;
            text-decoration: none;    
        }
    }

    &--newsletter-btn {
        width: 99%;
        height: 5rem;
        font-size: $default-font-size;
        background-color: $color-primary;
        color: #fff;
        transition: background-color 300ms;
        margin-top: 1rem;
        &:hover {
            background-color: lighten( $color-primary, 3%);
            transform: scale(1.03);
        }
    }

    &--edit-btn {
        color: #fff;
        background-color: $color-primary;
        padding: 1rem 3.5rem;
        text-align: center;
        text-decoration: none;
        font-size: 1.4rem;

        &:hover {
            background-color: #ed8e48;
            color: #fff;
        }
        &--margin {
            margin-top: 1.5rem;
        }
        &--float-right {
            float: right;
        }
    }

    &--apartment-side-panel {
        background-color: $color-primary;
        color: $color-white;
        padding: 1rem 0;
        text-align: center;
        text-decoration: none;
        width: 100%;
        font-family: $condensed-font;
        font-size: 2.4rem;
        margin-bottom: 1rem;

        &:hover {
            background-color: lighten( $color-primary, 3%);
            color: #fff;
        }
    }

    &--getintouch {
        background-color: $color-primary;
        border: none;
        color: $color-white;
        padding: 1rem 3.9rem;
        text-decoration: none;
        display: flex;
        width: 90%;
        font-size: 2.4rem;
        cursor: pointer;
        font-family: $condensed-font;
        margin: 2rem auto;
    }

    &--preview {
        font-size: 1.6rem;
        border: 1px solid $color-primary;
        color: $color-dark-text;
        background-color: transparent;
        text-transform: uppercase;
        padding: 1.2rem 5rem;

        &:hover {
            color: #fff !important;
            background-color: $color-primary;
        }
    }

    &--delete, 
    &--back  {
        font-size: 1.6rem;
        text-transform: uppercase;
        background-color: #e7e7e7;
        color: $color-dark-text;   
        padding: 1.2rem 2rem;
        margin-right: 2rem;
    }
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

/* FAKE BUTTON */
.inputfile + label {
    font-size: 1.6rem;
    font-weight: 300;
    color: white;
    background-color: #D67C3A;
    display: inline-block;
    padding: 1.5rem 4rem;
    text-transform: uppercase;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color:#D67C3A;
}

.inputfile + label {
	cursor: pointer;
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    pointer-events: none;
    outline: none;
}

.btn-primary:active,
.btn-primary:hover,
.loginbtn:hover,
.loginbtn:active,
.loginbtn:visited,
.loginbtn:focus {
    border-color: transparent !important;
    outline: none;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        border: 0px solid #fff;
    }
}

.btn-primary:hover {
 background-color: #D67C3A !important;
}

.post:active,
.post:hover,
.post:focus .cover:active,
.cover:hover,
.cover:focus {
    border-color: $color-primary !important;
    box-shadow: none;
}

.preview:active,
.preview:hover,
.preview:focus {
    background-color: #d3cfcf !important;
    border-color: #d3cfcf !important;
    color: $color-dark-text;
    box-shadow: none;
}

.loginbtn {
    width: 25rem;
    height: 5rem;
    background-color: $color-primary;
}

.btn-primary:focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn:active {
    box-shadow: none !important;
    border-color: transparent !important;
}

.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    color: transparent !important;
}

.btn-primary,
.btn-outline-secondary:focus
    {
    box-shadow: none;
}

.btn-primary {
    border-color: $color-primary;
}

.btn-outline-secondary span {
    color: $color-white;
}

.btn-outline-secondary,
.btn-outline-secondary:visited,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
    background-color: transparent !important;
    border: none !important;
}

#btn-hm {
    background-color: $color-primary;
    border-color: #e6e6e6;
    height: 3.8rem;
    font-size: 1.4rem;
    font-family: $condensed-font;
    padding: .5rem;
    margin: 3px 0 0 0;
    width: 99%;
}

#btn span {
    padding: .8rem;
}

/* NEWSLETTER BUTTONS NEWS LIST*/
.btn-danger:active {
    background-color: $color-primary !important;
}

.send .btn-danger {
    height: 4.6rem;
    background-color: $color-white;
    color: $color-darker-grey;
    border-color: $color-primary;
    margin-bottom: 2rem;
}

.send .btn-danger:hover {
    background-color: $color-primary;
    color: $color-white;
}

.share-this-btn {
    background-color: transparent;
    text-transform: uppercase;

    &--fr {
        float: right;
    }
}

.button,
.button-2 {
    background-color: $color-light-2;
    border: none;
    color: $color-dark-text;
    padding: 1.5rem 3.2rem;
    text-align: center;
    text-decoration: none;
    font-size: $default-font-size;
    margin: 0px 2px;
    cursor: pointer;
    align-self: center;
}

.button-2:hover {
    background-color: $color-dark-text;
    color: rgb(255, 255, 255);
}

.button:hover {
    background-color: $color-primary;
    color: rgb(255, 255, 255);
}

.button.analytics:hover {
    background-color: $color-primary;
    color: $color-white;
}

.button.analytics {
    display: flex;
    background-color: $color-light-1;
    color: $color-dark-text;
    padding: 1.5rem 3.2rem;
    font-size: $default-font-size;
    cursor: pointer;
    justify-content: center;
    width: 90%;
    margin: 5px auto 0 auto;
}

/* MODAL BUTTONS */

.sendapproval {
    background-color: $color-primary;
    &:hover {
        background-color: #ed8e48 !important;
    }
    &:focus {
        background-color: #ed8e48 !important;
    }
    &:active {
        background-color: #ed8e48 !important
    }
}

.nevermind {
    background-color: #d3cfcf !important;
    border-color: #d3cfcf !important;
    color: $color-dark-text;
    box-shadow: none;
    &:hover {
        color: $color-dark-text;
    }
    &:active {
        color: $color-dark-text;
    }
}

.cover {
    background-color: $color-primary;
    border-radius: 0;
    border-color: $color-primary;
    height: 5rem;
}

.button-send:hover {
    background-color: $color-primary;
    color: rgb(255, 255, 255);
}

/* ADMIN BUTTONS  */

.edit-btn {
    display: inline-block;
    border: none;
    color: $color-white;
    background-color: $color-primary;
    padding: 1rem 3.5rem;
    text-align: center;
    text-decoration: none;
    font-size: 1.4rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: color .3s ease-out, transform .3s ease-out;
    &:hover {
        background-color: #ed8e48;
        color: #fff !important;
    }
    &--blogs {
        position: absolute;
        top: 6px;
        left: 0;
        z-index: 10;
        opacity: 1;
        background-color: transparent;
        &:hover {
            background-color: #ed8e48;
            color: #fff;
            transform: scale(1.3);
            transform-origin: left;
            opacity: 1;
        }
    }
    &--vid {
        position: absolute;
        top: -46px;
        left: -1px;
        background-color: transparent;
        opacity: 1;
        &:hover {
            background-color: #ed8e48;
            color: #fff;
            transform: scale(1.3);
            transform-origin: left;
            opacity: 1;
        }
    }
    &--apt {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 1;
        background-color: transparent;
        &:hover {
            background-color: #ed8e48;
            color: #fff;
            transform: scale(1.3);
            transform-origin: left;
            opacity: 1;
        }
    }
    &--dir {
        position: absolute;
        bottom: 50%;
        right: 5rem;
    }
    &--buzz {
        position: absolute;
        top: 145px;
        left: -1px;
        background-color: transparent;
        &:hover {
            background-color: #ed8e48;
            color: #fff;
            transform: scale(1.3);
            transform-origin: left;
            opacity: .9;
        }
    }
    &:focus {
        outline: 0px dotted;
        outline: 0 auto -webkit-focus-ring-color;
    }
    &:hover {
        background-color: #ed8e48;
        color: #fff;
    }
    &--frmr {
        margin-right: 5rem;
        margin-top: 2.5rem;
        float: right;
    }
    &--ntm {
        margin-right: 0;
        margin-bottom: 2rem;
    }
    &--default {
        margin-right: 0;
        float: none;
    }

    &--fixed {
        position: fixed;
        left: -90px;
        bottom: 0;
        opacity: .1;
        transition: 200ms all ease-in;

        &:hover {
            opacity: 1;
            left: 0;
        }
    }
}

.mkfeat {
    position: absolute;
    top: -295%;
    right: 0;
    z-index: 11;
    color: #fff;
    opacity: .9;
    padding: 1.2rem;
    transition: .3s;
    transform-origin: top right;
    &:hover {
        background-color: rgba( $color-dark-text, .5);
        color: #fff;
        opacity: 1;
        transform: scale(1.1);
    }
}

.mkfeata {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    color: #fff;
    opacity: .9;
    padding: 1.2rem;
    transition: .3s;
    transform-origin: top right;
    &:hover {
        background-color: rgba( $color-dark-text, .9);
        color: #fff;
        opacity: 1;
        transform: scale(1.1);
    }
}

.btn-featured,
.btn-approve {
    position: absolute;
    top: 13.8rem;
    left: 14.6rem;
    z-index: 2;
    color: #fff;
    opacity: .9;
    padding: 1.2rem;
    transition: .3s;
    transform-origin: top right;
    &:hover {
        background-color: rgba( $color-dark-text, .9);
        color: #fff;
        opacity: 1;
        transform: scale(1.1);
    }
}

.btn-approve {
    position: absolute;
    top: 0;
    left: 23.6rem;
    background-color: rgb(2, 177, 2);
    &:hover {
        background-color: rgb(21, 223, 21);
    }
}

.search .search--close {
    color: #fff;
}

