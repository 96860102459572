/* VIDEO GALLERY PAGE */

.main-video-container {
    padding: .3rem;
    @include respond(phone-min) {
        margin: 4.3rem 0;
        padding: 0;
    }

    @include respond(phone-min) {
        &--ad {
            margin: 4.3rem 0 0 0;
        }
    }

    @include respond (phone) {
        margin-bottom: 2rem;
    }
}

.fullscreenvid {
    height: 45.7rem;
    @include respond (phone) {
        height: 28rem;
    } 
}

.main-vid {
    overflow: hidden;
    @include transition-start;
    position:relative;
    &__img {
        height: 45.7rem;
        @include img-bg;
        position: relative;
        &:hover {
            @include hover-effect;
        }
        @include respond (phone) {
            height: 28rem;
            width: 100%;
        }
    }
}

.video-gallery {
    display: flex;
    flex-flow: row wrap;
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 .5rem;
}

.video-gallery > * {
  padding: .5rem;
  margin: .5rem;
}

.video-wrap { 
    width: calc(25% - 1rem);
    flex: 0 1 calc(25% - 1rem);

    @include respond (images3) {
        width: calc(33.333% - 1rem);
        flex: 0 1 calc(33.333% - 1rem);
    }
    @include respond (images2) {
      width: calc(50% - 1rem);
      flex: 0 1 calc(50% - 1rem);
  }
    @include respond (images1) {
        flex: 0 1 100%;
    }
}

.video {
    @include transition-start;
    overflow: hidden;
    &__img {
        height: 22rem;
        @include img-bg;
        &:hover {
            @include hover-effect;
        }
    }
}

.video__content {
    min-height: 11.6rem;
    height: 1px;
    border: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    p {
        text-align: center;
        padding: 0 2rem;
        line-height: inherit;
        margin-bottom: 0;
        /* some inherited p reset */
    }
}