/* APARTMENTS PAGE */
.apartments-page {
  .news-head, .filter-container {
    margin: 3.5rem 2.5rem;
  }

  .filter-container {
    background-color: $color-light-1;
    padding: 1.5rem;
    margin-bottom: -1rem;
  }
}

.flex-container-base {
  display: flex;
  margin: 2rem 0 0 0;
}

.apartment-search-hero {
  background: linear-gradient(
    rgba(97, 70, 70, 0.4), 
    rgba(0, 0, 0, 0.4)
  ),
  url('/images/apartment-search-background.jpg');

  background-repeat: no-repeat,no-repeat;
  background-size: cover,cover;
  background-position: center center;
  width: 100%;
  min-height: 75vh;

  .search-wrapper
  {
    padding-top: 25vh;
    text-align: center;
    @include respond(phone) {
      padding-top: 15vh;

      .btn {
        width: 100%;
        font-size: 2rem;
        padding: .8rem;
      }
    }

    // #search-button {
    //   margin-left: -5px;
    // }

    h1 {
      color: white;
      font-size: 7rem;
      font-weight: 400;
      margin-bottom: 2rem;
      @include respond(phone) {
        font-size: 5rem;
      }
    }

    .typeahead-container {
      width: 55rem;
      margin: auto;
      font-size: 3rem;
      
      @include respond(phone) {
        width: 100%;
      }

      .tt-input, .tt-hint {
        height: 55px;
        font-size: 2rem;
      }
      .btn {
        font-size: 2rem;
      }
    }
  }  
}

.filter-container {
  background-color: $color-light-1;
  padding: 1.5rem;
}

.head-hero-wrapper {
  flex: 1 1 70%;
  @media screen and (min-width: 848px) {
    margin-right: 4rem;
  }
 }

.head-hero-wrapper--videos {
  @include respond(tab-port-min) {
    margin-right: 4rem;
    width: 100%;
  }
}

.text-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  color: $color-white;
  position: absolute;
  overflow: hidden;
  max-width: 100%;
  transform: translateX(-4px);
  -ms-transform: translateX(0);
  bottom: 0;
  height: 22%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;
}


.hero-apt-overlay {
  font-family: $condensed-font;
  font-size: 3rem;
  margin: 0 6rem 0 0;

  @include respond(phone) {
    font-size: 2rem;
    margin: 0 4rem 0 0;
  }
}

.location {
  font-family: $condensed-font;
  font-size: 1.8rem;
  margin: 0 6rem 0 0;
  @include respond(phone) {
    font-size: 1.5rem;
    margin: 0 4rem 0 0;
  }
}

.apartments-wrapper {
  margin: 3rem auto 0 auto;
  display: flex;
  flex-flow: row wrap;

  @include respond (tab-port-min) {
      padding: 0 0rem;
  }

  @include respond (tab-land-min) {
      max-width: 146rem;
  }

  @include respond(phone) {
    margin: 2rem auto 0 auto;
  }

  & > * {
    padding: .5rem;
    margin: .5rem;
  }

    &--floorplans {
      margin: 0 auto;
      & > * {
        margin: 1rem;
        padding: 0;
      }
  }
}

.apt-horizontal {
  max-height: 2000px;
  position: relative;
  overflow: hidden;

  @media (min-width: 576px) {
    max-height: 1040px;
  }

  @media (min-width: 848px) {
    max-height: 94rem;
  }

  .load-more {
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: 100%;
    text-align: center; 
    margin: 0; 
    padding: 3rem 0 .5rem; 
    background: #FFFFFF;

    .reg-button {
      font-size: 1.6rem;
      border: 1px solid $color-primary;
      color: $color-dark-text;
      background-color: transparent;
      text-transform: uppercase;
      padding: 1.2rem 5rem;

      &:hover {
          color: #fff !important;
          background-color: $color-primary;
      }
    }
  }
}


.apartment-wrap {
  width: calc(25% - 1.01rem);
  //flex: 0 1 calc(25% - 1rem);
  margin-bottom: 2rem;
  @include respond (images3) {
    width: calc(33% - 1rem);
    //flex: 0 1 calc(33% - 1rem);
  }
  @include respond (images2) {
    width: calc(50% - 1rem);
    //flex: 0 1 calc(50% - 1rem);
  }
  @include respond (images1) {
    width: 100%;
    //flex: 1 1 100%;
  }
}

.apt {
  overflow: hidden;
  @include transition-start;
  &__img {
    height: 25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &:hover {
      @include hover-effect;
    }
  }
  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
    color: $color-white;
    position: absolute;
    overflow: hidden;
    width: 100%;
    bottom: 0;
    padding: 1.5rem 0;
    display: flex;
    flex-flow: column nowrap;

    & > h3 { 
      padding-left: 2rem;
      margin-bottom: .9rem;
    }
  }
  
  &__text {
    font-size: 2rem;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.content {
  border: 1px solid $color-light-2;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 2rem;

  & > * {
    padding-left: 2rem;
  }

  &__address {
    h3 {
      font-size: 2rem;
      font-family: $condensed-font;
      margin-bottom: 2.2rem;
    }
  }

  &__baths {
    margin: .6rem 0;
  }

  &__rooms {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
  }

  &__details {
    font-weight: 300;
    font-style: italic;
    font-size: 1.6rem;
  }

  &__prices {
    font-style: italic;
  }

  &__btn {
    display: block;
    text-align: center;
    background: #f6f6f6;
    padding: 1.5rem 0;
    margin-top: 2rem;
    border-top: 1px solid #e6e6e6;
    transition: 300ms all ease-in-out;

    &:hover {
      color: #fff !important;
      background-color: $color-dark-text;
    }
  }
}

.apt-size__cost {
  margin-right: 3px;
}

.divider {
  margin: -5px 10px 0 10px;
  font-weight: 500;
}

.mobility h2 {
  font-size: 2.4rem;
  font-family: $condensed-font;

  @include respond (phone-min) {
    font-size: 5rem;
    padding: 0 1rem;
  }
}


.mobility-list {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  flex: 0 1 auto;
  width: calc(33% - 1.8rem);
  padding: 0 1rem;
  margin: 2rem auto 0;
  text-align: left;
  height: auto;

  @media (max-width: 576px) {
    justify-content: space-around;
  }

  .cflex {
    padding-left: .5rem;

    @media (min-width: 576px) {
      padding-left: 2rem;
    }
  }

  h4 {
    width: 100%;
    padding-top: 2rem;
    text-align: left;
    align-self: flex-start;
    padding-left: .5rem;

    @media (min-width: 576px) {
      padding-left: 2rem;
    }
  }

  .i {
    float: left;
    padding: 2rem 0rem 3rem 0;
    margin-bottom: 0;
    width: 7rem;
    height: 6rem;

    @media (min-width: 576px) {
      width: 7rem;
    }
  }

  img {
    width: 100%;
    max-width: 90px;
    max-height: 60px;
  }
  
  ul {
    display: flex;
    color: #707070;
    float: left;
    margin-right: 2rem;
    padding-right: 4rem;
  }

  .score {
    display: flex;
    align-items: flex-start;
    flex: 0 1 auto;
    font-size: 4.2rem;
    font-weight: 300;
    // margin-bottom: -3rem;
    color: #5E5D5D;

    @media (min-width: 576px) {
      font-size: 7rem;
    }
  }

  p {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    flex: 0 1 auto;
    line-height: 1.4;
    text-transform: capitalize;
    margin-top: 10rem;
    margin-bottom: 2rem;
    width: 50%;

    @media (min-width: 848px) {
      margin-top: 0;
      margin-left: 4rem;
      padding-left: 2rem;
      padding-top: 0;
      }
    }
}

