.error-page {
    text-align: center;
    margin-bottom: 5rem;
    
    h1 {
        font-size: 12rem;
    }

    p {
        font-size: 3rem;
    }
}