.pagination-container {
  font-size: 1.4rem;
  margin: 1rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
}


.pagination-container--full-width {
  margin: 2rem auto 0rem auto;
  order: 9;

}

.pagination-container {
  margin: 2rem auto 4rem auto;
  order: 9;
}

.pagination-item {
  list-style-type: none;
  display: inline-block;
  border-right: 1px solid #d7dadb;
  transform: scale(1) rotate(19deg) translateX(0px) translateY(0px) skewX(-10deg) skewY(-20deg);
  cursor: pointer;
}

.pagination-item:hover,
.pagination-item.is-active {
  background-color: $color-primary;
  border-right: 1px solid $color-white;
}

.pagination-item:hover .pagination-link,
.pagination-item.is-active .pagination-link {
  color: $color-white;
 
}

.pagination-item.first-number {
  border-left: 1px solid #d7dadb;
}

.pagination-item.next {
  border-right: none;
}

.pagination-link {
  padding: .9em 1.4em;
  display: inline-block;
  text-decoration: none;
  color: #8b969c;
  transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(20deg) skewY(0deg);
  cursor: pointer;
}

.pagination-link--wide:hover {
  color: $color-primary;
}

.pagination-link--wide.first:before,
.pagination-link--wide.last:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination-container-dir {
  font-size: 1.4rem;
  margin: 2rem auto 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media screen and (max-width:650px) {
  .hide-elements {
    display: none;
  }
  .pagination-item.back {
    margin-right: 6rem;
    cursor: pointer;
  }
  .pagination-item.front {
    margin-left: 6rem;
    cursor: pointer;
  }

  .pagination-item {
    list-style-type: none;
    display: inline-block;
    border-right: 1px solid #d7dadb;
    transform: scale(1) rotate(19deg) translateX(0px) translateY(0px) skewX(-10deg) skewY(-20deg);
  }

  .pagination-link {
    padding: .9em 1.4em;
    display: inline-block;
    font-size: 1.8rem;
    text-decoration: none;
    color: #8b969c;
    background: #f6f6f6;
    transition: .3s;
  }

  .pagination-item.back {
    border-right: none;
  }

  .pagination-link:hover {
    background-color: $color-primary;
    }

  .pagination-item:hover {
    background-color: transparent;
  }
}