.table {
    &.centered td {
        vertical-align: middle;
    }

    /* Table Rank for Best Places to Work*/
    &.table-rank {
        th {
            background-color: #EEEEEE;
            font-size: 2.1rem;
            font-weight: normal !important;
            font-stretch: normal;
            border: none;
        }

        tbody tr {
            border-bottom: 1px solid #dee2e6;
            border-top: none !important;
        }

        .rank {
            font-size: 6rem;
            font-family: Montserrat;
            font-weight: 200 !important;
            padding-left: 25px;
        }
    }    

    &.table-striped-double {
        tbody tr:nth-of-type(4n+1) {
            background-color:rgba(0,0,0,.05)
        }
    }    
}
