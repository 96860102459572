.floorplan {
    max-width: 100%;
    background-color: #fff;
    padding-right: 0 !important;

    &__content {
        max-width: 100%;
    }

    &__dialog {
        max-width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column;
        pointer-events: auto;
        height: 100%;
    }

    &__text-block {
        padding: 0 2rem;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: left;
        flex-direction: column;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        h4 {
            font-size: 2.5em;
        }
    }

    &__header {
        max-width: 100%;
        display: flex;
        padding: .2rem 2rem;
        margin: 0;
        border-bottom: 1px solid #d6d6d6;

       button {
            flex: 0 1;
            cursor: pointer;
            margin: 1rem;
            padding: 0;
 
            &:focus {
                outline: none;
            }

            span {
                font-weight: 300;
                font-size: 7.5rem;

                &:hover {
                    color: $color-primary;
                }
            }
        }
    } 

    .modal-social {
        padding-top: 1rem;

        a {
            margin-left: 1rem;
            color: $color-primary;

            &:hover {
                color: $color-dark-text
            }
        }
    }

    &__body {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-grow: 1;
        padding: 2%;
        height: 80vh;


        @media (min-width: 768px) {
            padding-right: 5%;
            padding-left: 5%;
            height: 80vh;
        }
    }

    &__img {
        position: relative;
        width: 100%;
        margin: 0 auto 3rem;
        height: auto;
        max-height: 80%;
        text-align: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .apartment-info {
        position: relative;
        display: flex;
        flex-flow: column;
        padding-top: 3rem;
        justify-self: center;

        .list-divider {
            margin: 0 auto;
            align-items: center;
            text-align: center;
            
            li {
                display: inline-block;
                padding-right: 1rem;
            }
    
            li+li {
                border-left: 1px solid #dfdfdf;
                padding-left: 1rem;
            }
        }

        .modal-title {
            font-size: 2.5em;
            margin: 0 auto;
            display: flex;
            flex-flow: row;
            align-content: center;
            padding-bottom: .2rem;
            font-weight: 500;
        }
    }
}


