.js [data-animation="fade-in-left"].animated {
  -moz-animation-name: fade-in-left;
  -o-animation-name: fade-in-left;
  -webkit-animation-name: fade-in-left;
  animation-name: fade-in-left;
}    
.js [data-animation] {
  -moz-animation-duration: 0.4s;
  -o-animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.js [data-animation="fade-in-left"] {
  opacity: 0;
}

.js [data-animation="fade-in-left"].animated {
  -moz-animation-name: fade-in-left;
  -o-animation-name: fade-in-left;
  -webkit-animation-name: fade-in-left;
  animation-name: fade-in-left;
}

  .cookiebox {
      width: 100%;
      height: 50px;
      background-color: rgba(255,255,255,0.82);
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
      -webkit-box-shadow: 10px -12px 22px -20px rgba(0,0,0,0.42);
      -moz-box-shadow: 10px -12px 22px -20px rgba(0,0,0,0.42);
      box-shadow: 10px -12px 22px -20px rgba(0,0,0,0.42);
      display: flex;
      align-items: center;
      justify-content: space-around;
      transition: height 0.3s ease-out,opacity 0.3s,padding 0.1s;
      opacity: 1;
  }

  .cookiebox .btn {
      background-color: teal;
      color: #fff;
      font-size: 14px;
  }    

  .exit-btn {
      cursor: pointer;
      color: teal;
  }    

  .edit{
      align-self:  flex-end;
      color: #fff !important;
  }
  
  .span #overlay-text {
      color: white !important;
      font-size: 1.6rem !important;
      margin: 0 auto 0 auto !important;
      padding: 5% !important;
  }
  
  .footlist img {
      background: rgb(46, 49, 55) !important;
  }
  
  /* use to disable back and next button in paging */
  a.disabled {
      /* Make the disabled links grayish*/
      color: gray;
      /* And disable the pointer events */
      pointer-events: none;
    }
  
    .video-thumbnail {
      display: inline-block;
      cursor: pointer;
      margin: 30px;
    }
  
    .video-thumbnail::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0 20px 30px;
      border-color: transparent transparent transparent #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -10px 0 0 -7px;
    }
  
    .video-thumbnail:hover::before {
      border-color: transparent transparent transparent #d67c3a;
  }
  
  form button#btn-nl {
    background-color: #D67C3A !important;
    border-color: #D67C3A;
  }
  .grecaptcha-badge {
    display: none;
  }
  
  
  .post {
      margin-right: 2rem !important;
  }

  .single-searching .news-head {
    margin-bottom: 2rem;
  }
  
  .searching{
    margin-left: 0rem !important;
    padding-right: 5rem;
    flex: 1 1 70%;
    padding-bottom: 1em;  
  }
  
  .searching .input-group-append {
    border-top: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
  }
  
  .searching .search__btn {
    margin-left: 0rem !important;
    background: #F6F6F6;
  }
  
  .searching span {
    padding: 0px 28px 0px 2px;
  }
  
  .searching .search__btn i {
    color: #B1B1B1;
    font-size: 1.5rem;
    
  }
  
  
  .searching p {
    padding-top: 13px;
    padding-bottom: 12px;
    text-align: left;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu a{
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-content a{
    cursor:pointer;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #f1f1f1;
  }   
  
  .dropdown-menu.search.show a {
    color: black;
    padding: 4px 16px;
    text-decoration: none;
    display: block;
    font-size: 1.6rem;
  }                  
  button#searchvalS {
    border: none;
    background: transparent;
    color: #6c757d;
    font-size: 2rem;
    padding-right: 2rem;
  }
  
  
  /* Forgot password */
  .card {
    &__wrapper {
      max-width: 100%;
      margin: 2rem;

      @include respond(phone-min) {
        max-width: 75rem;
        margin: 0 auto 10rem auto;
      }
    }

    &__header {
      padding: 1.75rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0,0,0,.03);
      border-bottom: 1px solid rgba(0,0,0,.125);
      text-align: center;
      font-size: 2.6rem;

      @include respond(phone-min) {
        font-size: 3.6rem;
        font-weight: 300;
      }
    }

    &__body {
      padding: 1em;
      @include respond(phone-min) {
        padding: 3rem; 
    }

      h2 {
        margin-top: 1rem;
        font-size: 1em;
        font-weight: 300;
        @include respond(phone-min) {
          font-size: 1.5em; 
        }
      }

      form {
        padding: 2rem;
      }
    }
    
    &__back {
      text-align: center;
      &:hover {
        color: #ed8e48;
      }
    }
  }

  .card-body .back a {
    
  }
  
  .card .modal-body {
    padding: 4rem;
  }
  
  .card .modal-body p{
    padding-bottom: 1.5em;
  }
  
  .card .modal-content {
    border-radius: 0rem;
    border: none;
  }
  
  .card .modal-body .user-btn{
    padding: 1rem 6.5rem;
  }
  
  /* Login and Register */
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: #000;
  }
  
  .login-register label{
    color: #2E3137;
    float: left;
  
  }
  
  .login-register .modal-content {
    border-radius: 0rem;
    border: none;
  }
  
  .login-register .modal-content form{
    padding-top: 13px;
  }
  
  .login-register .modal-header {
    background: #2E3137;
  }
  
  .login-register .close {
    color: #fff;;
    opacity: 1;
  }
  .login-register .modal-body {
    padding: 4rem 4rem 2rem 4rem;;
  }
  
  .login-register h1{
    font-size: 3.5rem;
    color: #2E3137; 
  
  }
  
  .login-register .social-wrap {
    padding: 2rem 0px;
  }
  
  .login-register .btn-social {
    display: inline-block;
    border: none;
    color: #2E3137 !important;
    background-color: #F6F6F6 !important;
    padding: 1.5rem 3.5rem;
    text-align: left;
    text-decoration: none;
    font-size: 2.5rem;
    cursor: pointer;
    margin: 5px auto;
  
  }
  
  .login-register .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem;
  }
  
  .login-register img.rounded.mx-auto.d-block {
    width: 14%;
  }
  .login-register .btn-social:hover{
    background: #e0e0e070 !important
  }
  
  .login-register .modal-body img{
    width: 10%;
    margin-right: 41px;
  }
  
  .login-register .modal-footer {
    background: #F6F6F6;
    justify-content: center;
    padding: 24px 0px;
  }
  .login-register .modal-footer h2 {
    margin: 0em;
    font-size: 1.7em;
    color: #2E3137 ;
  }
  .login-register .modal-footer button {
    border: none;
    background: transparent;
    font-size: 1.7em;
    color: #D67C3A;
  }
  
  .login-register .btn-login{
    color: #fff;
    background: #D67C3A;
    font-size: 12px;
    width: 100%;
    padding: 1.2rem 0px;
    margin-top: 1.9rem;
  
  }
  
  .login-register .forgot{
    text-align: left;
    color: #D67C3A;
  } 
  
  .login-register .form-control{
    margin-bottom: 6px;
  }
  
  .login-register.form-row.align-items-center a {
    color:#D67C3A;
  }
  
  /* external login */
  .padding40{
    padding: 3rem 15vw !important;
  }
  
  .padding50{
    padding: 3rem 20vw !important;
  }
  
  .padding50 label {
    font-size: 1.5rem !important;
  } 
  
  .padding50 .account-btn {
    display: inline-block;
    padding: 1.5rem 8rem;
    color: #ffffff;
    background-color: #D67C3A;
    border-radius: 0;
    border: 1px solid #D67C3A;
    transition: .2s;
    align-self: flex-end;
    cursor: pointer;
  }
  
  .padding50 .login-btn-area {
    margin-top: 3rem;
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  
  .padding40 h2{
    color: #D67C3A;
    font-weight: 600;
    padding: 1.5rem 0rem;
  }
  
  .title-name {
    border-bottom: 3px solid #d67c3a;
    display: inline-block;
    font-weight: 600 !important;
  }
  
  .login-register .modal-dialog {
    max-width: 500px !important;
  }
  
  /* Login Social Media */
    .social:nth-child(1){
      background: #ff9900 url(/images/social-media/fb.png) no-repeat 30px center; 
      display: inline-block;
      border: none;
      color: #2E3137 !important;
      background-color: #F6F6F6 !important;
      padding: 1.5rem 8.5rem;
      text-align: left;
      text-decoration: none;
      font-size: 2.5rem;
      cursor: pointer;
      margin: 5px auto;
      width: 100%;    
    }
  
    .social:nth-child(2){
      background: #ff9900 url(/images/social-media/Google.png) no-repeat 30px center;     
      display: inline-block;
      border: none;
      color: #2E3137 !important;
      background-color: #F6F6F6 !important;
      padding: 1.5rem 8.5rem;
      text-align: left;
      text-decoration: none;
      font-size: 2.5rem;
      cursor: pointer;
      margin: 5px auto;
      width: 100%;
    }  
  
    .social:nth-child(3){
      background: #ff9900 url(/images/social-media/LinkedIn.png) no-repeat 30px center;     
      display: inline-block;
      border: none;
      color: #2E3137 !important;
      background-color: #F6F6F6 !important;
      padding: 1.5rem 8.5rem;
      text-align: left;
      text-decoration: none;
      font-size: 2.5rem;
      cursor: pointer;
      margin: 5px auto;
      width: 100%;
    }  
   
    @media only screen and (max-width: 425px){
      .social:nth-child(1){
        font-size: 1.7rem !important;
      }
  
      .social:nth-child(2){
        font-size: 1.7rem !important;
      }
      
      .social:nth-child(3){
        font-size: 1.7rem !important;
      }    
    }
  
    @media only screen and (max-width: 320px){
      .social:nth-child(1){
        font-size: 1.5rem !important;
        padding: 1.5rem 7.5rem !important;
      }
  
      .social:nth-child(2){
        font-size: 1.5rem !important;
        padding: 1.5rem 7.5rem !important;
      }
      
      .social:nth-child(3){
        font-size: 1.5rem !important;
        padding: 1.5rem 7.5rem !important;
      } 
  
    }  
  
  
  /* date Picker */
  .form-group.input-daterange input {
    text-align: left;
    border-radius: 0;
  }
  
  /* Webcast Media Files  */
  
  .c-flie{
    font-size: 1.6rem !important;
    font-weight: 300;
    color: white;
    background-color: #D67C3A;
    display: inline-block;
    padding: 1.5rem 4rem;
    text-transform: uppercase;
    cursor: pointer;
  } 
  
  
  .upload {
    color: white;
    background-color: #D67C3A;
    display: inline-block;
    padding: 4px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .upload input {
    opacity: 0;
  }
  
  .alert1{
    display: none;
  }
  
  @media screen and (max-width: 479px) {
    .rssitem p {
        font-size: 1.5rem;
    }
    
  }
  
  /* Button color */
  .btn-orange{
    background: #D67C3A !important;
    color: #fff !important;
  }
  
  
  b, strong {
    font-weight: 900 !important;
  }
  
  .directory-about{
    float: left;
    display: inline-block;
    clear: both;
    padding-right: 10px;
  }
  
  span.select2.select2-container.select2-container--default.select2-container--below {
    width: 100% !important;
  }
  
  .margin-top30{
    margin-top: 30px;
  }
  
  button.login__btn {
    padding: 0;
    width: 100%;
    border: none;
    margin-bottom: 20px;
  }
  
  button.loginform__btn.loginform__btn--email {
    border: none;
  }
  
  button.loginform__btn {
    border: none;
  }
  
