.site-wrapper {
    max-width: $container-width;
    display: flex;
    flex-flow: row nowrap;
    margin: 9rem auto 0 auto;
    padding: 0 1rem;

    @include respond (tab-port-min) {
        padding: 0 1.5rem;
    }

    @include respond (tab-land-min) {
        max-width: 144rem;
    }

    &--block {
        display: block;
    }
    &--less-margin {
        margin-top: 7.6rem;
    }
    &--start {
        margin-top: 10rem auto;
    }
}

.news-container {
    margin: 0 auto;
    flex-basis: 70%;
    @include respond(phone-min){
        padding-right: 4rem; 
    }

    @include respond (stories) {
        flex-basis: 100%;
    }
    p {
        font-size: 1.8rem;
        line-height: 1.6;
        color: $color-darker-grey;
        
        @include respond(phone) {
            font-size: 1.5rem;
        }
    }
    &--moderate {
        margin: 0;
    }
}

.news-head {
    max-width: $container-width;
    display: block;
    border-bottom: 2px solid $color-primary;

    @media screen and (max-width: 848px) {
        width: 99%;
    }

    @media screen and (max-width: 848px) {
        margin-bottom: 3rem;
    }

    @include respond(phone-min) {
        margin-bottom: 5rem;
        border-bottom: 3px solid $color-primary;
    }

    &--less-margin {
        margin-bottom: 3.1rem;
    }
    &--no-margin {
        margin-bottom: 0;
    }
    &--noborder {
        border-bottom: none;
        margin-right: 0;
    }
    &--article {
        margin-bottom: .5rem;
    }

    &--greyline {
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 7px;
    }

    h1 {
        font-family: $condensed-font;
        font-size: 7rem;
        color: $color-dark-text;
        font-weight: 300;
        position: relative;
        z-index: -1;
        @media screen and (max-width: 848px) {
            font-size: 3.4rem;
        }
    }

    &__headline {
        line-height: 1.2;
        font-size: 2.4rem !important;

        @include respond(phone-min) {
            font-size: 4.8rem !important;
        }
        &--share-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    h2 {
        font-size: 4.5rem;
        line-height: 1.2;
        font-family: "Roboto Condensed", sans-serif;
        margin: 1.5rem 0 0 0;

        @media screen and (max-width: 848px) {
            font-size: 2.8rem;
            padding-right: 0;
        }
        @include respond(phone) {
            font-size: 2.4rem;
        }
    }
    &--normargin {
        margin-right: 0;
    }

    &__links {
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    &__ojline {
        border-bottom: 3px solid $color-primary;
        width: 94.90%;
        &--fullwidth {
            width: 98.5%;
        }
        @media screen and (max-width: 848px) {
            width: 100%;
        }
    }

    &__links {
        /* APARTMENT BREADCRUMBS */
        display: flex;
        flex-flow: row wrap;
        a {
            color: #999;
            font-size: 1.4rem;
            text-transform: uppercase;
            margin: 0 .3rem;
            &:hover {
                color: $color-primary;
                text-decoration: none;
            }
        }
        span {
            margin: 0 .3;
            color: #999;
        }
    }
}
.ad-article {
    display: flex;
    position: relative;
    @include respond (images1) {
        display: block;
    }
}
.responsive-container {
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;
}

.flex-wrap-news {
    flex: 1 1 100%;
    display: flex;
    flex-flow: column nowrap;
    transition: all 1s;
    position: relative;
    &:hover h4 {
        color: #777;
    }
    @include respond (stories) {
        flex: 1 1 50%;
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0rem;
        }
    }
    @include respond (phone) {
        flex: 1 1 100%;
    }
    @include respond (stories) {
        &--ad {
            order: 7;
        }
    }
}

.news-article {
    display: flex;
    flex-flow: row nowrap;
    padding-right: .5rem;
    &__link {
        display: block;
        position: relative;
        overflow: hidden;
        flex: 1 1 27.4rem;
        height: 19.2rem;
        @include respond (stories) {
            margin-bottom: 1.5rem;
        }
        &__img {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transition: all 0.2s ease-in-out;
            height: 100%;
            &:after {
                content: '';
                display: block;
                height: 100%;
                width: 100%;
                transition: all 0.2s ease-in-out;
            }

            &--contain {
                background-size: contain;
            }
        }
    }
    &--ad {
        max-width: 100%;
        background-color: $color-light-1;
        padding: 2rem 1rem 4rem 1.5rem;
        margin: 3rem 0;
        display: flex;
        flex-flow: column nowrap;

        h3 {
            display: inline-block !important;
            margin-right: auto;
            font-size: 2rem;
            font-family: $condensed-font;
            border-bottom: 3px solid $color-primary;
            margin-bottom: 2rem;  
            @media screen and (min-width:0\0) {max-width: 9.5%;}
        }
    }
    
    &--ad-bot {
        margin: 1rem 0 4rem 0;
    }
}

.news-article__link:hover .news-article__link__img:after {
    background: rgba(0, 0, 0, 0.3);
}

.news-article__link:hover .news-article__link__img {
    transform: scale(1.1);
}

.date-box {
    flex: 1 1 27.56rem;
    height: 19.2rem;
    border: solid 1px $color-primary;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    p, .month {
        font-family: 'Montserrat', sans-serif;
        font-size: 2.5rem;
        margin: 0 0 -1.5rem 0;
        color: $color-darker-grey;
        font-weight: 300;
        padding: 0;
    }
    h1, .day {
        font-family: 'Montserrat', sans-serif;
        font-size: 9.6rem;
        color: $color-darker-grey;
        font-weight: 100;
        line-height: 1.2;
    }
    h2, .year {
        font-family: 'Montserrat', sans-serif;
        font-size: 3.5rem;
        margin: -2.5rem 0 0 0;
        color: $color-darker-grey;
        font-weight: 300;
        line-height: 1.2;
    }
}

.directory-img {
    width: 27.56rem;
    height: 19.2rem;
    flex: 0 1 27.56rem;
    border: 1px solid #d67c3a;
    padding-top: 6rem;
    padding-bottom: 6rem;
    position: relative;
    @include respond (stories) {
        flex: 0 1 50%;
        width: 100%;
    }
}

.news-line-d {
    background-color: $color-light-2;
    margin: 4rem 0;
    height: 1px;
    width: 100%;
}

.article-content {
    margin-left: 2rem;
    flex: 1 1 70%;
    @include respond (stories) {
        text-align: center;
    }
    &--ad {
        text-align: left;
        margin: auto 2rem;
    }
    &--jobs {
        margin: 0;
        .job-row {
            padding-top: 1.5rem;
        }
    }
    &__subhead {
        color: $color-dark-text;
        font-family: $condensed-font;
        display: block;
        line-height: 1.2;
        font-size: 3rem;
        transition: .3s;
        margin-bottom: 0;
        @include respond (stories) {
            line-height: 1.5;
            color: $color-dark-text;
            font-size: 1.8rem;
            padding: .5rem 0;
            font-family: $default-font;
        }
    }
    &__text,
    p {
        font-size: 1.8rem;
        line-height: 1.6;

        @include respond (phone) {
            font-size: 1.5rem;
            line-height: 1.6;
            margin-top: -6px;
        }

    }
    &--events,
    &--directory,
    &--webcasts {
        position: relative;
        margin-top: 2.5rem;
        padding-right: 0;
        @include respond (phone) {
            padding: 0;
            margin-top: .5rem;
        }
    }

    &--events,
    &--directory {
        @include respond (stories) {
            margin-top: 0;
        }
    }

    &--press-releases {
        margin-top: 0;
        @include respond (stories) {
            font-size: 1.8rem;
            line-height: inherit;
            margin-bottom: 2rem;
            margin-top: 1rem;
            margin-left: 0;
            padding-right: 0;
        }
    }
}

/* LIST PAGES  */
@include respond (stories) {
    .article-content__text,
    .article-content-blog__text {
        display: none;
    }
    .article-content-ad p,
    .article-content-blog-ad p {
        padding: 0 1.2rem;
        margin: 0 auto;
        overflow: hidden;

    }
    .news-line-d {
        display: none;
    }
    /* SIDE IMAGES HIDE */
    #sidepic {
        display: none;
    }
    .news-article {
        display: block;
    }
}

