.side-wrapper {
  display: flex;
  flex-flow: column wrap;
  margin: 0 auto;
  flex: 0 0 30%;
}

.latest {
  display: flex;
  flex-flow: column wrap;
  background-color: $color-light-1;
  height: 60rem;
  justify-content: space-between;
  padding: 2rem 2rem 4rem;
  &__head {
    h2 {
      font-family: $condensed-font;
      color: $color-dark-text;
      font-size: 4.4rem;
      margin-top: 1rem;
    }
  }
  &__ojline {
    border-bottom: 2px solid $color-primary;
    width: 22rem;
    &--vid {
      border-bottom: 2px solid $color-primary;
      width: 16rem;
    }
  }
}

.sidenews {
  a {
    display: flex;
    flex-flow: row nowrap;
  }
  p {
    font-size: 1.4rem;
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    flex: 0 0 66%;
  }
  img {
    height: 7.5rem;
    width: 12rem;
    object-fit: fill;
  }
}

.newsletter-side {
  display: flex;
  flex-flow: column wrap;
  max-width: 100%;
  border: 1px solid $color-light-2;
  align-items: center;
  text-align: center;
  padding: 3rem 0px;
  margin: 2rem 0;
  height: auto;

  &__head {
    h2 {
      font-family: $condensed-font;
      display: inline;
      color: $color-dark-text;
      font-size: 3.6rem;
      padding-bottom: 1rem;
    }
    p {
      padding: 1.2rem 0;
      font-size: 1.5rem;
      color: $color-darker-grey;
      position: relative;
    }
    img {
      transform: translateY(-8px);
      height: 2.5rem;
      width: 2.5rem;
      @include respond (stories) {
        display: none;
      }
    }
  }
  &__ojline {
    border-bottom: 2px solid $color-primary;
    width: 100%;
    margin: auto;
    padding-bottom: .3rem;
  }
  @include respond (stories) {
    display: none;
  }
}

@include respond (stories) {
  .latest {
    max-height: 60rem;

    img {
      display: none;
    }

    a {
      p {
        flex: 1 1 100%;
      }
    }
  }
}