.blog-author {

  &__bio {
    display: flex;
    background-color: #f6f6f6;
    flex-flow: column;
    margin: 0 0 2rem 0;

    @include respond(tab-land-min) {
      flex-flow: row;
      justify-content: center;
      position: relative;
      margin: inherit;
      padding: 20px;
      margin: 4rem 0;
    }
   }

  &__profile {
    @include respond(tab-land-min) {
      flex: 0 0 75%;
    }
    
    h4 {      
      position: relative;
      @include respond(tab-land-min) {
        margin-left: 4rem;
      }

      &:after {
        @include respond(tab-land-min) {
          position: absolute;
          content: "";
          width: 2rem;
          height: .5rem;
          background: $color-primary;
          left: -3rem;
          transform: translateY(11px);
        }
      }
    }
  }

  &__img{
    max-width: 100%;
    margin: 0 0 2rem 0;
    flex: 0 0 200px;
    object-fit: cover;

    @include respond(tab-land) {
      overflow: hidden;
    }
 
    @include respond(tab-land-min) {
      flex: 1 0 25%;
      height: 30rem;
      margin: -20px;
      padding-right: 2rem;
    }
    @media screen and (min-width:0\0) {height:300px}
}
  
  &__btn {
    cursor: pointer; 
    float: right;
    padding-right: 4rem;
    margin-bottom: 1rem;
    padding-left: 0rem;
    margin-top: 1rem;
    color: $color-primary;
    font-weight: 400;
    @include respond(tab-land-min) {
      padding-left: 4rem;
      margin-bottom: 0;
      float: right;
    }
  }
}


