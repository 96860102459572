.landing-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 144rem;
    padding: 2rem 1rem;
    margin: 0 auto;

    .contact-form {
        background-color: rgba(46, 49, 55, 0.6);
        border: 0;
        color: #FFFFFF;
        max-width: 600px;

        .btn {
            text-transform: uppercase;
        }
    }

    .resources-header {
        padding-top: 5vh;
      
        @include respond(phone) {
          padding-top: 1vh;
        }
        
        h1 {
          color: white;
          font-size: 8rem;
          font-weight: 400;
          padding-left: 2rem;
      
          @include respond(phone) {
            font-size: 5rem;
          }
      
            .subheader {
                display: block;
                font-size: 4rem;
                @include respond(phone) {
                    font-size: 3rem;
                }
            }
        }
    }
      
      .hero-form {
        padding-top: 13vh;
        max-width: 110rem;
        justify-content: space-between;
        
        h2 {
            font-size: 3rem;
            padding-bottom: 0;
        }
    }
}

.tagline {
    line-height: 1.5;
    color: #FFF;
    font-family: Roboto,sans-serif;
    font-size: 2rem;
    text-align: left;
    padding: .5rem 2rem 3rem;
    max-width: 760px;
    
    @include respond(phone) {
        font-size: 1.5rem;
    }
}

.resource-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    max-width: 144rem;
    padding: 2rem 1rem;
    margin: 0 auto;

    p {
        line-height: 2;
        color: #555;
        font-family: Roboto,sans-serif;
        font-size: 2rem;
        text-align: center;
        padding: 2rem 2rem 3rem;
        
        @include respond(phone) {
            font-size: 1.6rem;
        }
    }

    h2 {
        font-size: 3.75em;
        color: #2e3137;
        font-family: Roboto Condensed,sans-serif;
        font-weight: 400;
        margin-bottom: 1rem;
        padding-top: 2rem;
        text-align: center;

        @include respond(phone) {
            font-size: 3.3rem;
            padding-left: 2rem;
        }
    }

    ul {
        line-height: 2;
        color: #555;
        font-family: Roboto,sans-serif;
        font-size: 2rem;
        text-align: left;
        justify-content: center;
        padding: 2rem 4rem;

        @include respond(phone) {
            font-size: 1.6rem;
          }
    }

    .resource-bottom {
        text-align: left;
        padding: 0 4rem;

        @include respond(phone) {
            padding: 0 1rem;
          }

        h2 {
            text-align: left;
            padding-left: 2rem;
        }

        p {
            text-align: left;
        }
    }

    .resource-stats {
        padding: 0 0 3rem;

        .stat-block {

            p { 
                font-size: 2rem;
                line-height: 1.5;
            }
            
            .number {
                display: block;
                font-size: 4.2rem;
                font-weight: 300;
                color: #2e3137;
            
                @media (min-width: 576px) {
                font-size: 10rem;
                }
            }
        }
    }
}

.form-wrapper {
    background-color: #f6f6f6;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 3rem;

    .form-container {
        max-width: 144rem;
        padding: 2rem;

        @include respond(phone) {
            padding: .5rem;
        }
        
        .highlight-quote {
            margin: 0 auto;

            h2 {
                font-size: 3.75em;
                color: #2e3137;
                font-family: Roboto Condensed,sans-serif;
                font-weight: 400;
                margin-bottom: 1rem;

                @include respond(phone) {
                    font-size: 3.3rem;
                }
            }
        }
    }
}

.slide-out-form-button {
    padding: 2rem;

    .btn {
        padding: 1rem 2rem !important;
        text-transform: uppercase;
    }
}

.slide-out-form-wrapper {
    &.reg::after{
        left: calc(50% - 50px) !important;
    }

    .resource-reg-header {
        background: #F6F6F6;
        padding: 5px !important;
        margin-bottom: 3rem;
        h2{
            text-align: left;
        }        
    }
}

.ada-hero {
    background: linear-gradient( rgba(97, 70, 70, 0.6), rgba(0, 0, 0, 0.6)), url('/images/digital-inclusion.jpg');
    background-repeat: no-repeat,no-repeat;
    background-size: cover,cover;
    background-position: center center;
    width: 100%;
    min-height: 70vh;
}
