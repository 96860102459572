/* LOGIN  */
.pd50 {
    padding: 0 2rem 5vh 2rem !important;

    @include respond(tab-port-min) {
        padding: 0 5vh 10vh 5vh !important;
    }
}

.border {
    box-shadow: 1px 0px 8px 0px rgba(0,0,0,0.16);
    padding: 4rem;
}
.register-account-flex {
    display: flex;
    flex-flow: row nowrap;

    @include respond (tab-port) {
        display: block;
    }
}

.login-form {
    padding: 2rem 3rem 5rem;
    border: 1px solid #e6e6e6;
    flex: 0 1 65%;
    -ms-flex: 0 1 70%;
    box-shadow: 0 0 6px 0 rgba(70, 70, 70, 0.1);
    margin: auto;

    @include respond (tab-port) {
        margin-right: 0;
    }
  
    h1 {
        font-size: 2.4rem;
        margin-bottom: 3rem;
        position: relative;
        width: 100%;
    }
}

.submit {
    background-color: $color-primary;
}

.sign-in-head h1, .sign-in-head .h1 {
    font-size: 5.5rem;
    font-family: $condensed-font;
}

.agreement-check {
    padding: 2rem 0;
}

.sign-in-head {
    padding-bottom: 5rem;
}

.sign-in-head h1 {
    font-size: 5.5rem;
    font-family: 'Roboto condensed', 'San sarif';
}

.agreement-check {
    padding: 20px 0;
}

.account-btn {
    display: inline-block;
    padding: 1.5rem 8rem;
    color: $color-primary;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid $color-primary ;
    transition: .2s;
    align-self: flex-end;
    cursor: pointer;

    @include respond (phone) {
        align-self: center;
        padding: 1.5rem 4rem;
    }

    &:hover {
        background-color: $color-primary;
        color: #fff;
    }

    &--less-margin-on-mobile {
        @include respond(phone) {
            margin-top: 3rem;
        }
    }
}

.member-section {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: auto;
    flex: 0 1 auto;
    -ms-flex: 0 1 30%;
    -ms-min-height: 39rem;
    -ms-height: 1px;
    padding: 2rem 3rem;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 6px 0 rgba(70, 70, 70, 0.1);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
       margin-bottom: 12rem; 
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 56.25em) {
        margin-bottom: 5rem; 
     }
}

.member-signin {
    h1 {
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }
}

.login-options {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;

    &__rememberme {
        label {
            margin-bottom: 0 !important;
        }
    }
}

.login-btn-area {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .forgot {
        font-size: 1.6rem;
        color: $color-dark-text;

        @include respond(phone) {
            align-self: center;
            padding: 1rem 0;
        }

        &:hover {
            color: $color-primary;           
        }
        
        @include respond (phone) {
            margin-bottom: 2rem;
        }
    }
    .user-agreement {
        margin-left: 1.5rem;
        font-size: 1.5rem;

        @include respond (phone) {
            margin-bottom: 3rem;
        }
    }

    @include respond (phone) {
        flex-flow: column;
        align-items: flex-start;
    }
}

.login-services {
    h4 {
        font-size: 2rem;
        margin-bottom: 2rem;
        @include respond (tab-port-min) {
            font-size: 3rem;
        }
    }
}