/* COLORS */
$color-primary: #D67C3A;
$color-medium-grey:#777;
$color-darker-grey: #555;
$color-light-1: #f6f6f6;
$color-light-2:#e6e6e6;
$color-dark-text:#2E3137;
$color-white:#fff;

/* FONT SIZE  */
$headline-sm: 4.4rem;
$default-font-size: 1.6rem;


/*  FONT STYLES  */
$condensed-font:'Roboto Condensed', sans-serif;
$default-font: 'Roboto', sans-serif;



/* HEADING STYLES  */
$featured-head: 2rem;
$container-width: 144rem;
$container-padding: 1rem;

.marginbottom4 {
    margin-bottom: 4rem;
}

/* HELPER CLASSES */
.mb5 {
    margin-bottom: 5rem;
}
.mt1 {margin-top: 1rem !important; }
.margintop2 {
    margin-top: 4rem !important; 
    @media screen and (max-width: 650px) {
        margin-top: 2rem !important;
    }
}
.mt3 {margin-top: 3rem !important; }
.mt4 {margin-top: 4.5rem; }
.mt6 {margin-top: 6rem !important; }
.mb1 {margin-bottom: 1rem !important;}
.mb2 {margin-bottom: 2rem !important;}
.mb3 {margin-bottom: 3.1rem !important;}
.pr4 {padding-right: 4.5rem;
    @include respond(stories) {
        padding-right: 4.5rem;
    }
    @include respond(stories) {
        padding-right: 3rem;
    }
    @media (max-width: 848px) {
        padding-right: 0;
    }
}

.mr4 {
    margin-right: 4.5rem;
    
    @include respond(stories) {
        margin-right: 4.5rem;
    }

    @include respond(stories) {
        margin-right: 3rem;
    }

    @media (max-width: 848px) {
        margin-right: 0;
    }
}

.fr {float:right;}
.m80 { margin-left: 70%;}

hr {
    margin: 0;
}
