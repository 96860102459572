.floorplan {
    &--wrapper {
        border-top: 1px solid $color-light-2;   
    }

    &--item {
        display: flex;
        width: 100%;
        margin: 0 !important;
        padding: 1rem  !important;
        border-bottom: 1px solid $color-light-2;
        height: 40rem;
        flex-flow: column;
        justify-content: space-around;

        @media (min-width: 576px) {
            height: 20rem;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }

        @media (min-width: 848px) {
            height:  17rem;
            flex-direction: row;
            justify-content: space-evenly;
        }

        .two-thirds {
            display: flex;
            flex-flow: column;
            width: 100%;

            @media (min-width: 576px) {
                display: flex;
                flex-flow: column;
                width: 66%;
            } 

            @media (min-width: 848px) {
                flex-flow: row;
                width: 75%;
                justify-content: space-between;
            }
        }

        .image {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 1.5rem;

            @media (min-width: 576px) {
                width: 33%;
                border-right: 1px solid $color-light-2;
            }

            @media (min-width:848px) {
                width: 25%;
                border-right: 1px solid $color-light-2;
                align-items: center;
                padding: 1.5rem;
            }

            img {
                max-height: 12rem;
                max-width: 100%;
                margin: auto auto;
                -o-object-fit: contain;
                object-fit: contain;
                cursor: pointer;
            }
            img:hover {
                transition: all .1s ease-in-out;
                transform: scale(1.05);
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            text-align: center;


            @media (min-width: 576px) {
                text-align: left;
                padding-left: 5rem;
              }
            

            @media (min-width: 848px) {
                flex-direction: column;
                justify-content: center;
                width: 50%;
                padding-left: 5rem;
                text-align: left;
            }

            h3 {
                font-size: 2.5rem;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }
            
            span.pipe {
                padding: 0 1rem;
            }
        }

        .view-more {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 2rem 5rem;

            @media (min-width: 576px) {
                justify-content: left;
                padding: 1rem 5rem;
            }

            @media (min-width: 848px) {
                display: flex;
                justify-content: center;
                width: 30%;
                align-items: center;
                padding: 1rem 5rem;                
            }
        }
    }
}