@mixin btn-ln {
    display: inline-block;
    padding: 2rem 6rem;
    max-width: 100%;
    border: 1px solid $color-white;
    margin-top: 4rem;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;

    @media screen and (max-width:768px) {
        padding: 1rem 4rem;
        margin-top: 2rem;
    }

    @include respond(phone) {
        font-size: 1.8rem;
        padding: 1rem 2rem;
    }

    &:focus {
        outline: none;
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.247);
    }

    span {
        font-size: 2.8rem;
        color: $color-white;
        margin: auto;
        text-decoration: none;

        @include respond(phone) {
            font-size: 2rem;
        }
    }
}

@mixin cta {
    color: $color-white;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 8rem;
    font-weight: 500;
    line-height: 1.2;
 
    @include respond(images3) {
        font-size: 6rem;
    } 

    @media screen and (max-width:768px) {
        font-size: 3.6rem;
    }
}

@mixin hover-effect {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    opacity: .7;
}

@mixin transition-start {
    transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    &:hover {
        background-color: rgba(0, 0, 0, 7);
    }
}

@mixin img-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
}


@mixin respond ($breakpoint) {
    @if $breakpoint==phone {
        @media (max-width: 37.5em) {
            /* 600 / 16 */
            @content
        }
        ;
    }
    @if $breakpoint==tab-port {
        @media (max-width: 56.25em) {
            //900
            @content
        }
        ;
    }

    @if $breakpoint==images3 {
        //992
        @media (max-width: 62em) {
            @content
        }
        ;
    }
    @if $breakpoint==images2 {
        //768
        @media (max-width: 48em) {
            @content
        }
        ;
    }
    @if $breakpoint==images1 {
        //576
        @media (max-width: 36em) {
            @content
        }
        ;
    }
    /* NEWS STORIES BREAK POINTS */
    @if $breakpoint==stories {
        //1088
        @media (max-width: 68em) {
            @content
        }
        ;
    }
    @if $breakpoint==tab-land {
        //1200
        @media (max-width: 75em) {
            @content
        }
        ;
    }
    /*   CONTAINER PADDING  */
    @if $breakpoint==desktop {
        //1800
        @media (max-width: 112em) {
            @content
        }
        ;
    }

    @if $breakpoint==desktop-min {
        //1800
        @media (min-width: 112em) {
            @content
        }
        ;
    }

    @if $breakpoint == phone-min {
        @media only screen and (min-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == tab-port-min {
        @media only screen and (min-width: 56.25em) { @content };     //900px
    }
    @if $breakpoint == tab-land-min {
        @media only screen and (min-width: 75em) { @content };    //1200px
    }

    @if $breakpoint == big-desktop-min {
        @media only screen and (min-width: 112.5em) { @content };    //1800
    }


}
