.bottom-news {
    max-width: $container-width;
    padding: 1rem 1rem 4rem 1rem;
    margin: 0 auto;

    &__header {
        display: inline-block;
        padding-left: 1rem;
        margin-bottom: 1rem;
        
        h2 {
            display: block;
            font-weight: 500;
            font-size: 5rem;
            font-family: $condensed-font;
            color: $color-dark-text;

            &:after {
                background: none repeat scroll 0 0 $color-primary;
                content: "";
                margin-top: -.5rem;
                display: block;
                height: 3px;
                position: relative;
                z-index: -1;
            }
        }
    }
    
    // &__ojline {
    //     border-bottom: 3px solid $color-primary;
    //     width: 25rem;
    //     margin-top: -1rem;
    // }
    @include respond (images1) {
        display: none;
    }
}

.list-container {
    display: flex;
    flex-flow: row wrap;
    text-align: center;
}

.gallery {
    flex: 1 1 22%;
    padding: 0 1rem;
    margin-top: 2rem;
    @include respond (images3) {
        flex: 1 1 33.33333333%;
    }
    @include respond (images2) {
        flex: 1 1 50%;
    }
    &__wrapper {
        overflow: hidden;
        @include respond (images3) {
            height: 19.9rem;
        }
        @include respond (images2) {
            height: 17.9rem;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 5);
        }
    }
    &__img {
        background-position: center;
        background-size: cover;
        height: 22rem;
        @include transition-start;
        &:hover {
            @include hover-effect;
        }
    }
    &__border {
        border-left: 1px solid $color-light-2;
        border-right: 1px solid $color-light-2;
        border-bottom: 1px solid $color-light-2;
        min-height: 11.8rem;
        height: 1px; // IE dumb
        display: flex;
        align-items: center;
        p {
            padding: 0 .5rem;

            @include respond (stories) {
                max-width: 30.7rem;
                margin: auto;
            }
        }
    }
}