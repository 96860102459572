.best-places{
    &-header {
        max-width: $container-width;
        display: block;
        border-bottom: 2px solid $color-primary;

        h1 {
            font-family: $condensed-font;
            font-size: 7rem;
            color: $color-dark-text;
            font-weight: 300;
            position: relative;
            z-index: -1;
            @media screen and (max-width: 848px) {
                font-size: 3.4rem;
            }

            sup {
                font-size: 35%;
                top: -1.5em;
            }
        }       
    }

    h2 {
        font-family: Montserrat;
        font-size: 4rem;
        color: $color-dark-text;
        font-weight: 300;
        position: relative;
        z-index: -1;
        @media screen and (max-width: 848px) {
            font-size: 2.4rem;
        }
    }
}