/* NEWS ARTICLE */
.inside-article {
    display: inline-block;
    width: 100%;

    &__profile {
        margin-top: 3rem;
    }

    &__image {
        height: 26.7rem;
        width: 38rem;
        margin: 0px 1rem 1rem 0;
        float: left;
        clear: both;
        @include respond (phone) {
            height: auto;
            max-width: 100%;
            float: none;
            padding-right: 0;
            margin: 0px 2rem 1rem 0;
        }
    }    

    &--directory {
        @include respond (phone-min) {
            margin-top: 2rem;
        }
    }

    @include respond (phone) {
        padding-right: 0;
    }

    h3,
    h2 {
        font-size: 4.4rem;
        font-family: $condensed-font;
        @media screen and (max-width: 848px) {
            font-size: 2.8rem;
        }
    }
    h4 {
        font-size: 2.4rem;
        font-family: $condensed-font;
    }
    ol, ul {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        margin-left: 1.5em;
    }
    li {
        font-size: 1.8rem;
        line-height: 1.8;
        color: #555;
        margin-bottom: 1rem;
        position: relative;
    }
    p {
        margin-bottom: 2rem;
    }

    .bold {
        font-weight: 700 !important;
    }
}

/* SHARE VIA SOCIAL MEDIA DROP DOWN & HASHTAGS */

.form-group-news {
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.tag-wrap {
    margin: 0 0 .7rem 0;
    width: 100%;
    /*   padding-right: 1rem; */
}

.taglines {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 1 auto;
    justify-content: space-between;
    align-items: center;

    &__share {
        font-size: 1.4rem;
        color: #999;
        text-transform: uppercase;
        margin-left: 3px;
    }

    &__tags {
        font-size: 1.4rem;
        color: #999;
        text-transform: uppercase;
        font-style: italic;
    }

    &__source,
    &__source a {
        font-size: 1.4rem;
        font-family: $condensed-font;
        color: #999;
        text-transform: uppercase;

        @include respond(phone) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__source a {
        font-style: italic;
    }

}

    @include respond(phone) {
        flex-flow: column;
        align-items: flex-start;
    }
    .dropdown {
        @media screen and (max-width: 650px) {
            align-self: flex-start;
        }
    }

.greyline {
    border-top: 1px solid $color-light-2;
    width: 100%;
    margin-top: 3rem;
}

/* WEBCAST */

.webcast-img {
    @include img-bg;
    max-width: 100%;
    height: 35rem;
    position: relative;
    @media screen and (max-width: 848px) {
        height: 24rem;
        margin-right: 0rem;
    }
}

.bg-video__content {
    width: 100%;
    height: auto;
    opacity: .9;
    overflow: hidden;
    @media screen and (max-width: 848px) {
        width: 100%;
        height: auto;
    }
}

.article-title {
    font-family: 'Roboto condensed', sans-serif;
    font-size: 3rem;
    font-weight: 500;
}

.wrapper-highlights {
    margin-top: 2rem;
    p {
        margin-bottom: 1rem;
    }
}

.wrapper-sq {
    display: flex;
    &:last-of-type {
        margin-bottom: 2rem;
    }
}

.wrapper-highlights {
    display: flex;
    flex-flow: column;
}

.mods {
    margin: 3rem 0 2rem 0;
    font-size: 4.4rem;
    font-family: 'Roboto condensed', sans-serif;
}

@media screen and (max-width:650px) {
    .taglines,
    .share-this-btn {
        font-size: 1.3rem;
        padding: 0;
    }
}
