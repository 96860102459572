.twitter-typeahead {
    // .tt-menu {
    //   width: 100%;
    //   text-align: left;
    //   background: white;
    //   padding: 0 1px;

    //   .tt-suggestion {
    //     padding: 3px;
    //   }
    // }
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;

    .tt-query {
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    .tt-hint {
      color: #999
    }

    .tt-menu {
      text-align: left;
      width: 100%;
      margin: 5px 0;
      padding: 8px 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
              border-radius: 1px;
      -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
        -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
              box-shadow: 0 5px 10px rgba(0,0,0,.2);
    }

    .tt-suggestion {
      padding: 3px 20px;
      font-size: 1.6rem;
      line-height: 24px;
    }

    .tt-suggestion:hover {
      cursor: pointer;
      color: #fff;
      background-color: #d67c3a;
    }

    .tt-suggestion.tt-cursor {
      color: #fff;
      background-color: #d67c3a;

    }

    .tt-suggestion p {
      margin: 0;
    }
  }