.col-form-label-lg {
    padding: 1rem;
    font-size: $default-font-size;
    line-height: 1.5;
}

input[type=text]::-ms-clear {
    display: none;
}

.mfb-form .form-control-lg {
    padding: 1rem;
}

.post-type {
    margin: 4.3rem 0;
}

.account-type {
    font-size: 3.6rem;
    font-family: $condensed-font;
    margin-top: 1rem;
}

.mfb-form {
    padding-left: .5rem !important;
}

.account-type.uppercase {
    text-transform: uppercase !important;
}

.coverimg {
    margin-top: 2rem;
}

.thumb {
    height: 25rem;
}



.field {
    font-size: 2rem;
}

.breadcrumb {
    background-color: $color-white;
    padding: 0.75rem .5rem 0 .5rem;
    margin-bottom: -1.5rem;
    font-size: 1.8rem;
    font-family: $condensed-font;
}

.breadcrumb a:hover {
    color: $color-primary;
}

.required label::after {
    color: $color-primary;
    content: "*";
}

.container.mfb-width {
    max-width: $container-width !important;
}

.post-type {
    margin: 4.3rem 0;
}

.account-type {
    font-size: 3.6rem;
    font-family: $condensed-font;
    margin-top: 1rem;
}

.mfb-form {
    padding-left: .5rem;
}

.account-type.uppercase {
    text-transform: uppercase;
}

.coverimg {
    margin-top: 2rem;
}

.thumb {
    height: 25rem;
}

.cover {
    background-color: $color-primary;
    border-radius: 0;
    border-color: $color-primary;
}

.field {
    font-size: 2rem;
}

.mfb-form input::placeholder, .mfb-form textarea::placeholder {
    font-size: $default-font-size;
    color: #aaa7a7;
}

.breadcrumb {
    background-color: $color-white;
    padding: 0.75rem .5rem 0 .5rem;
    margin-bottom: -1.5rem;
    font-size: 1.8rem;
    font-family: $condensed-font;
}

.breadcrumb a:hover {
    color: $color-primary;
}

.required label::after {
    color: $color-primary;
    content: "*";
}

.analytics-img {
    padding-left: 0;
}

.analytics-img img {
    width: 100%;
}

.contact-form {
    background-color: $color-light-1;
    border: 1px solid $color-light-2;
    margin: 1.8rem 0 0 0;
}

/* FORMS */

.contact-apt,
.col-apt {
    padding: 0 1rem;
}

.form-control {
    border-radius: 0;
    padding: .8rem .85rem;
    line-height: 1.6;
}

.reg textarea {
    border-top: none;
    border-left: none;
    border-right: none;
  }