.news-head__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-content {
    padding: 0 0 4rem;
    h4 {
        color: #2E3137;
        font-family: "Roboto", sans-serif;
        line-height: 3.8rem;
        font-size: 2.4rem;
        transition: .3s;
    }
    p {
        font-size: 1.8rem;
        line-height: 3rem;
    }
}

.user-content {
    padding: 2rem 2rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    
    &__btn-wrap {
        display: flex;
        flex-flow: column nowrap;

        a {
            margin-top: 1rem;
        }
    }
    &__btn-wrap-approve {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        float: right;
        &__approvebtn {
            margin-top: 1.5rem;
        }
    }
    &:first-child {
        border-top: 1px solid #ddd;
    }
    &:nth-child(odd) {
        background-color: #f6f6f6;
    }
    &__wrap {
        flex: 0 0 auto;

        &--news {
            flex: 0 0 80%;
        }

        span {
            &:last-child {
                padding-left: 1rem;
            }
        }
        ul {
            list-style: none;
            li {
                font-size: 1.6rem;
                &:first-child {
                    font-weight: 300;
                    font-size: 1.8rem;
                    margin-bottom: 1rem;
                }
            }
        }
        @include respond(phone) {
            flex: 0 0 auto;
        }
    }
    /* WRAP END */
    @include respond(phone) {
        flex-flow: column;
        align-items: stretch;
    }
}

.directory {
    width: 100%;
    &__info {
        font-size: 3rem;
        font-family: $default-font;
        font-weight: 300;
        padding: 2rem;
    }
    th {
        padding: 2rem;
    }
    tr {
        &:nth-child(odd) {
            background-color: #f6f6f6;
        }
    }
    td {
        padding: 2rem;
        font-size: 1.8rem;
        font-family: $default-font;
        font-weight: 300;
    }
}

.users {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 0 3rem;
}

.vid-content {
    display: block;
    flex: 1 1 28rem;
    width: 31.7rem;
    padding-bottom: 4rem;
    position: relative;
    margin: 1rem;

    &__vids {
    width: 31.7rem;
    height: 19.2rem;
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 1.5rem;

        &__img {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            transition: all 0.3s ease-in-out;
            height: 100%;

            &:after {
                content: '';
                display: block;
                height: 100%;
                width: 100%;
               
            }
        }
    }
    &__text {
        font-size: 1.8rem;
        text-align: center;
    }
}

.vid-content__vids:hover .vid-content__vids__img {
    transform: scale(1.1);
}

.vid-content__vids:hover {
    .vid-content__vids__img:after {
        background-color: rgba(0, 0, 0, 0.2);
    }
}