/* CONTACT PAGE */

.intro {
    max-width: 110rem;
  }
  
  .contact-list {
    margin: 4rem auto;
  }
  

  .list1 .form-control,
  .list2 .form-control,
  .list3 .form-control {
    height: 4.6rem;
  }
  
  .form-control:focus {
    box-shadow: none;
    border-color: $color-primary;
  }
  
  .list1 #name {
    margin-bottom: 1.5rem;
  }
  
  .list2 #Email,
  .list2 #Company {
    margin-bottom: 1.5rem;
  }
  
  .list3 .phone,
  .list3 .form-control {
    margin-bottom: 1.5rem;
  }
  
  .list3 .form-control {
    color: #888;
  }
  
  .msg #text-area {
    margin-bottom: 1.5rem;
  }
  
  .fname {
    margin-bottom: 1.5rem;
  }
  
  /* RSS FEED PAGE */
  
  .rsslist {
    padding-left: 0;
  }
  
  .rssitem2 {
    display: flex;
    justify-content: space-between;
  }
  
  .item-wrap {
    margin: 5rem auto;
  }
  

  .fa-rss {
    color: $color-light-2;
    margin: 2rem 0;
  }
  
  .fa-rss:hover,
  .rssitem2 h1:hover,
  .rssitem2:hover {
    color:$color-primary;
  }