.flex-mobile-to-column {
  @include respond(images1) {
    flex-flow: column;
  }
}

.webcast-reg { 
  &--col {    
    
    &.col1 { 
      border-right: none;    
      flex: 0 0 100%;
      max-width: 100%;
      
      @media (min-width: 768px) {
        border-right: 2px solid $color-primary;  
        flex: 0 0 33.3%;
        max-width: 33.3%;     
      }
      
      @media (min-width: 1250px) { 
        flex: 0 0 20%;
        max-width: 20%;
      }
    }

    &.col2 {    
      flex: 0 0 100%;
      max-width: 100%;
      
      @media (min-width: 768px) {    
        flex: 0 0 66.3%;
        max-width: 66.3%;   
      }
      @media (min-width: 1250px) { 
        flex: 0 0 80%;
        max-width: 80%;  
      }
    }

    div {
      font-size: 2rem;
      margin: auto;
    
      @media (max-width: 990px) {
        padding-top: 2rem;
      }
    }

    a {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      font-size: 1.6rem;
      background-color: #d67c3a;
      color: #fff;
      transition: background-color .3s;

      &:hover {
        height: 5rem;
        font-size: 1.6rem;
        background-color: transparent;
        color: #D67C3A;
        border: 1px solid #D67C3A;
      }
    }
  }

  &--header {
    font-size: 4rem !important;
    font-family: $condensed-font;
    line-height: 1.2;
  }
  &--date {
    font-size: 2.3rem !important;
    line-height: 1.2;
  }
}

.hero {
  flex: 1 1 46.4rem;

  @include respond(phone) {
    padding: 0 1rem;
    flex: 1 1 0.1rem;
  }

  &__content {
    position: relative;
    overflow: hidden;
    height: 45rem;
    cursor: pointer;
    @include respond (images3) {
      height: 40rem;
    }
    @include respond (images2) {
      height: 35rem;
    }
    @include respond (images1) {
      height: 25rem;
      flex: 1 1 0rem;
    }
    &:hover .hero__img {
      transform: scale(1.0);
      transition: all 0.3s ease-in-out;
    }

    &:hover .hero__featured-header {
      opacity: 0;
    }
  }

  &__img {
    background-position: center center;
    background-size: cover;
    height: inherit;
    transform: scale(1.1);
  }

  &__content-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgba(0, 0, 0, 0.65);

    @include respond(tab-port) {
      display: none;
    }

    &:hover {
      @include respond(tab-port-min) {
        width: 100%;
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__content-head {
    color: white;
    margin: 4rem auto 0 auto;
    padding: 0 5%;
    font-size: 2.8rem;
    font-weight: 300;
  }

  #overlay-text {
    color: white;
    font-size: $default-font-size;
    margin: 0 auto 0 auto;
    padding: 5%;
  }
  
  &__featured-header {
    background-color: rgba(0, 0, 0, 0.5);
    color: $color-white;
    width: 100%;
    padding: 2.5rem;
    bottom: 0;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    h3 {
      font-size: 2rem;
      line-height: 3rem;
      margin: auto;
      font-weight: 300;
      @include respond (phone) {
        font-size: 1.7rem;
      }
    }
  }
}

.newslist {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  flex: 1 0 30%;
  justify-content: space-between;

  @include respond(tab-port) {
    display: none;
  }

  @include respond(tab-land) {
    justify-content: flex-start;
  }

  &__header {
    display: inline-block;
    margin-bottom: 2rem;
    font-family: $condensed-font;
    font-weight: 300;
    font-size: 4.4rem;
    color: $color-dark-text;
    margin:-.7rem 0 2rem 0;
    border-bottom: 2px solid $color-primary;
  }

  &__content {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin: 0 0 2rem 0;
    padding-right: 1rem;
 
    &__last-child {
      margin-bottom: 0;
    }
    p {
      font-size: 1.6rem;
      color: $color-darker-grey;
      line-height: 1.5;
      max-width: 41rem;

    }
    img {
      height: 7rem;
      width: 11rem;
      margin-right: 1rem;

      @include respond(tab-land) {
        display: none;
      }
    }
  }
}

.adspace {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column;

  video {
    margin: -1px;
    width: auto;
  }

  img { 
    margin-top: 1.8rem;
    height: auto;
    max-width: 320px;
  
    @include respond(images1) {
      width: 100%;
      max-width: 100%;
    }  
  }

  @include respond(images1) {
    //display: none;
    padding: 0 1rem;
  }
}

.news-articles {
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  & > div {
    padding: 0 1rem;
  }
}

/* NEWS STORIES */
.article {
  width: 25%;
  flex: 0 1 25%;
  -ms-flex: 0 0 auto;
  height: auto;
  margin-bottom: 2rem;

  @include respond (images3) {
    width: 33.333%;
    flex: 0 1 33.333%;
  }
  @include respond (images2) {
    width: 50%;
    flex: 0 1 50%;
  }
  @include respond (images1) {
    flex: 1 1 100%;
  }

  &__img-box-fx {
    overflow: hidden;
    transform: scale(1);
    &:hover {
      background-color: rgba(0, 0, 0, 5); 
    }
  }

  &__img-box {
    background-position: center center;
    background-size: cover;
    height: 23.7rem;
    &:hover {
        @include hover-effect;
      }

      @include respond (images3) {
        height: 21rem;
      }
      @include respond (images2) {
        height: 19rem;
      }
      @include respond (images1) {
        height: 18rem;
      } 
    }

  &__border {
    border: solid 1px $color-light-2;
    border-top: none;
    height: 1px;
    min-height: 12.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 1.6rem;
      padding: 0 .5rem;

      @include respond(phone) {
        text-align: left;
        padding: 2rem;
      }
    }

    @include respond(phone) {
      min-height: 10rem;
    }
  }
}

/* WEBCAST SECTIONS  */
.webcast-bg {
  background: url("../images/webcastsblur1.png") no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 12rem 0;
  align-items: center;

  @include respond(phone-min) {
    padding: 12rem 10vh;
  }

  @include respond(images2) {
      padding: 5rem 2rem;
    }
  }

.webcast {
  h3 {
    color: $color-white;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 2.6rem;

    @include respond(images2) {
      font-size: 2rem;
    }
  }
  &__logo-group {
    display: flex;
    align-items: center;
  }
  &__logo {
    height: 8rem;
    width: 8rem;
  }
  h2 {
    @include cta;
    margin-left: 2rem;
  }

  &__btn-ln {
    @include btn-ln;
    margin-left: 30%;
    h3 {
      font-size: 2rem;
      color: $color-white;
      margin: auto;
      text-decoration: none;
    }
  }

}

/* TRENDING SECTION  */
.trend {
  &__trending {
    display: inline-block;
    margin-bottom: 2rem;
    font-family: $condensed-font;
    color: $color-dark-text;
    font-size: 4.4rem;
    border-bottom: 3px solid #D67C3A;
    margin-left: 1rem;
  }

  &__flex-wrap-articles {
    max-width: $container-width;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-bottom: 4rem;

    & > div {
      padding: 0 1rem;
    }

    @include respond (images3) {
      display: block;
    }
  }

  &__pr {
    flex: 1 1 23%;
    padding: 0 0 0 2.5% !important;
   /*  margin-top: 5%; */

   @include respond(images3) {
     margin-top: 5%;
   }

    a {
      font-size: 2.5rem;
      line-height: 1.5;
      color: $color-dark-text;
      font-weight: 400;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.6rem;
      color: $color-medium-grey;
      font-weight: 300;
      line-height: 3rem;
      margin-top: 1rem;
    }
  }
}

/* BLOGS CALL TO ACTION */
.blog-bg {
  background: url("../images/blog-hero-pole.png") no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20rem 0;

  @include respond(phone-min) {
    padding: 20rem 5vw;
  }

  @media screen and (max-width:768px) {
      background-image: url("images/thinner.png") no-repeat;
      padding: 5rem 1rem;
  }

  .blog {
    h2 {
      @include cta;
    }
      p {
        font-size: 2rem;
        max-width: 50%;
        color: $color-white;

        @media screen and (max-width:768px) {
          font-size: 1.6rem;
          line-height: 2.5rem;
          max-width: 59%;
        }
      }
  
    &__btn-ln {
      @include btn-ln;
      margin-left: 30%;
      h3 {
        font-size: 2rem;
        color: $color-white;
        margin: auto;
        text-decoration: none;
      }
    }
  }
}

/* FIND US EVERYWHERE SECTION */

.orange-box {
  max-width: 90%;
  border: .7rem solid $color-primary;
  padding: 8rem;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  
  @media screen and (max-width:915px) {
      padding: 3rem 6rem;
      align-items: stretch;
    }
      @include respond (phone) {
        padding: 3rem 1.5rem;
        border: .3rem solid $color-primary;
    }

  h2 {
    font-size: 8rem;
    font-weight: 400;
    margin: 0 auto;
    color: $color-dark-text;
    @include respond(images3) {
      font-size: 6rem;
    } 

    @media screen and (max-width:768px) {
        font-size: 3.6rem;
    }
  }

  p {
    font-size: 2rem;
    color: $color-medium-grey;
    line-height: 1.8;
    font-weight: 300;
    max-width: 69.4rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;

    @include respond(images3) {
      font-size: 1.5rem;
      line-height: 1.6;
    } 
  }
  
  &__logos {
    display: flex;
    width: 90%;
    height: auto;
    margin: 4rem auto;
    padding: 1rem;
    flex-flow: row nowrap;
    justify-content: space-around;
    @include respond(phone) {
      flex-flow: column;
      margin: 1rem auto;
    }
  }
  .source img {
    width: 13vw;
    height: auto;
    min-width: 15rem;
    @include respond(phone) {
      flex-flow: column;
      margin-top: 1rem;
    }
  }
}

.input-file-event {
  margin: 2rem 0;
  width: 20%;
}

.text-container-ad-article {
  display: flex;
}

.text-container h4 {
  font-family: $condensed-font;
  font-size: 2.4rem;
  line-height: 3.4rem;
  color: $color-dark-text;
}

.directory-item {
  color: $color-light-2;
  display: flex;
  flex-flow: row;
}

.fa-address-card {
  color: $color-light-2;
}

.directory-item img {
  height: 19.2rem;
  width: 27.2rem;
  border: 1px solid $color-light-2;
  padding: 3.5rem;
}

.text-container-pr h4 {
  font-family: $condensed-font;
  font-size: 2.4rem;
  line-height: 3.4rem;
  color: $color-dark-text;
}

.text-body p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: $color-darker-grey;
}

.publish {
  margin-top: 5rem;
  margin-bottom: 5rem !important;

  &--create {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}

.publish-pr {
  margin: 2rem 0 6rem 0;
  padding-right: 4rem;
}

@media screen and (max-width:1200px) {
  /* HOMEPAGE */
  .envelope {
    width: 3rem;
    height: 2.5rem;
  }
  .vid-thumbnail-2 {
    height: 16.5rem;
  }
}



