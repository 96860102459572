.my-flex-container {
  height: 45.4rem;
  position: relative;
  display: flex;

  @media (max-width: 552px) {
    height: 38rem;
  }

  @media (max-width: 425px) {
    height: 32rem;
  }
}

.flex-container-apt {
  display: flex;
  flex-flow: row wrap;
  margin: 10rem auto 0 auto;

  @include respond (phone) {
    padding: 0 1.5rem;
  }

  @include respond (tab-port-min) {
    padding: 0 1.5rem;
  }

  @include respond (tab-land-min) {
      max-width: 144rem;
  }

}

.flexwrapper {
  display: flex;
  flex-flow: row;
  height: 45.8rem;
  position: relative;

  @media (max-width: 552px) {
    height: 38rem;
  }

  @media (max-width: 425px) {
    height: 32rem;
  }
}

.img-wrapper {
  display:flex;
  flex-flow: column;
  background-position: center;
  flex: 0 0 30%;
  padding: .5rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(85, 85, 85, 0.545);
  padding: 3rem 2rem;

  
  @media (max-width: 552px) {
    display: none;
  }
}

.side-block {
  flex: 1 0 30%;
  display: flex;
  flex-flow:column wrap;
  -ms-flex: 1 -1 30%;
  
  @media screen and (max-width: 855px) {
    -ms-display: block;
  }

  @include respond (tab-land) {
    padding: 0 1.5rem;
  }

  @include respond (phone) {
    padding: 0 .5rem;
  }
}

.contact-form {
  h2 {
    padding: 1.8rem 2.5rem;
    font-size: 3.6rem;
    display: inline-block;
    font-family: $condensed-font;
    @include respond(stories) {
      font-size: 2.6rem;
      padding: 1.6rem 2rem;
    }
  }
}

.apt-container-content {
  display: block;
  padding-right: 4.5rem;
  flex: 1 0 70%;
  -ms-flex: 1 0 65%;

  @media screen and (max-width: 855px) {
    padding-right: 0;
  }

  @include respond(tab-land) {
    padding: 0 1.5rem;
  }

}

.about-apartment-head {
  font-size: 4.4rem;
  font-family: "Roboto Condensed", sans-serif;

  @include respond(phone) {
    font-size: 2.4rem;
  }

}

.removepadding {
  /* removes the padding from the apartments */
  padding: 0;
}

.names {
  display: flex;
  flex-flow: row nowrap;
}

.contact-details {
  display: flex;
  flex-flow: row nowrap;
}

.contact-apt {
  flex-wrap: nowrap;
  margin: 1rem 0;
  font-size: 1.8rem;
}

.getin-hours {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.about-apt-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
  @include respond (phone-min) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

.office-hours-container {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid $color-light-2;
  background-color: $color-white;
  padding: 0 2rem 1rem 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  @include respond (phone-min) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  p {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 400;
  }

  h2 {
    font-size: 3.6rem;
    margin: 2.8rem 0;
    display: inline-block;
    font-family: $condensed-font;
    margin-right: auto;
    border-bottom: 2px solid $color-primary;
    @media screen and (min-width:0\0) {max-width: 45%;}
  
    @include respond(stories) {
      font-size: 2.6rem;
    }
  }

  @include respond(phone) {
    flex: 1 1 auto;
  }
  .call {
    font-size: 2rem;
  }
}

.info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 1.5rem 0;
  border-bottom: 1px solid $color-light-2;
}

.callmemaybe {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.tele {
  margin: 2rem auto;
}

.call {
  font-size: 2.8rem;
  margin: 2rem 0;
}

.section h2{
  font-size: 2.4rem;
  font-family: $condensed-font;

  @include respond (phone-min) {
    font-size: 4.4rem;
  }
}

.amenities-wrapper {
  max-width: $container-width;
  padding: 0 $container-padding;
  margin: 4rem auto;
 
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .section {
    padding: 3rem 0;
  
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  
    &:last-child {
      h2 {
        padding-bottom: 1rem;
      }
  
      p {
        max-width: 80%; 
        @include respond(images2) {
        max-width: 100%;
        }     
      }
    }

    &:first-child {
      padding-top: 0;
    }

    .amenities-list {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      margin-top: 1rem;
      margin-left: 2rem;
      max-width: 100%;
    
       @include respond(images2) {
         columns: 1;
         -webkit-columns: 1;
         -moz-columns: 1;
       }
    }
    
    .amenities-line {
      width: 70%;
      border: 1px solid $color-light-1;
      margin: 0 auto;
    }
  }
}

.details-box {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.details-box-head {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

 .unit {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid $color-light-2;
  align-items: center;
  width: 24%;
  max-width: 348px;
  flex: 1 0 20%;
  margin-bottom: 2rem;

  @include respond (images3) {
    width: 32%;
    flex: 1 1 calc(33% - 1rem);
  }
  @include respond (images2) {
    width: 48%;
    flex: 1 1 calc(50% - 1rem);
  }
  @include respond (images1) {
    width: 100%;
    flex: 1 1 100%;
  }

  @include respond (images3) {
    max-width: 100%;
  }

  p {
    font-size: 1.6rem;
    span {
      font-style: italic;
    }
  }

  &__bed {
    margin-right: 5px;
  }

  img {
    max-width: 100%;
    height: 20rem;
    margin: 3rem 0;
    object-fit: contain;
  }

  &__btn {
    width: 100%;
    display: block;
    background-color: #f6f6f6;
    border: none;
    color: $color-dark-text;
    padding: 1.5rem 3.2rem;
    text-align: center;
    margin-top: 1.5rem;
    font-weight: 400;
    border-top: 1px solid #e6e6e6;
    cursor: pointer;
    transition: 300ms ease-in-out all;

    &:hover {
      color: #fff !important;
      background-color: $color-dark-text;
    }
  }

  &__header {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    background: #f6f6f6;
    h2 {
      font-family: $condensed-font;
      font-size: 1.8rem;
      margin: 0;
    }
    p {
      margin-top: 3px;
      display: inline-block;
    }
  }
}

.amenities-container {
  display: flex;
  flex-flow: row nowrap;

  @media screen and (max-width: 768px) {
   display: block;
  }
}

.amen-items {
  font-size: 1.8rem;
  line-height: 3.5rem;
}

.pet-talk {
  max-width: 75%;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb li a:hover {
  color: $color-primary;
}

.order-container {
  display: flex;
}

.directory-links {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: -.5rem 0 1rem 0;
}

.directory-links a {
  color: #999;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.directory-links a:hover {
  color: $color-primary;
  text-decoration: none;
}

.directory-links span {
  margin: 0 .3rem;
  color: #999;
}

.dropdown-item {
  padding: 1.6rem;
  font-size: 1.6rem;
}

.dropdown-item:active {
  background-color: $color-light-1;
}

.socialicon {
  padding-right: 1rem;
  color: #999;
  font-size: 2rem;
}

