.login {
  font-family: $default-font;
  font-size: 1.8rem;
  color: $color-dark-text;
  padding-right: 2rem;
  margin-top: -.5rem;
}

.account-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login span {
  padding: 0 .5rem;
}

.navbar-toggler:not(:disabled) {
  cursor: pointer;
  font-size: 2rem;
}

.navbar-expand-lg > .container-fluid {
  padding: 0 2rem;

  @include respond (phone-min) {
    padding: 0 2rem;
  }

  @include respond(tab-land-min) {
    padding: 0 2.5rem;
  }

  @include respond(desktop-min) {
    padding: 0 1rem;
  }
}

.nav-content-wrapper {
  width: 142rem;
}

/* The side navigation menu */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: $color-dark-text;
  overflow-x: hidden;
  padding-top: 8rem;
  transition: 0.3s ease-in-out;
  }

.sidenav-search {
  margin: 2rem 0;
}

.form-control.mobile {
  padding: 1rem;
  max-width: 90%;
  margin: 0 auto;
  border-radius: .5rem;
}

#mySidenav .closebtn {
  position: absolute;
  top: 0rem;
  right: 2rem;
  font-size: 5rem;
  border-bottom: none;
}

#mySidenav a {
  text-decoration: none;
  padding: .7rem;
  font-size: 1.8rem;
  color: $color-white;
  display: block;
  transition: 0.3s;
  border-bottom: solid 1px rgb(67, 71, 80);
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background:$color-dark-text;
   }

  @media screen and (max-width:768px) {
      padding: .7rem;
      font-size: 1.8rem;
    }
    
  &:hover {
   background:rgb(67, 71, 80);
  }
}

#main {
  transition: margin-left .5s;
  padding: 2rem;
}

.navbar-dark {
  font-family: $condensed-font;
  background-color: $color-dark-text;
  padding: 0.8rem 0;
}

.navbar-dark .navbar-nav>li>a {
  margin-right: 1.8rem;
  color: $color-white;
  text-transform: uppercase;
  @include respond(tab-land) {
    margin-right: 1rem;
  }
}

.navbar-brand {
  display: block;
  background-repeat: no-repeat;
  width: 20rem;
  // height: 3.4rem;
  // background-image: /* url("../images/multifamilybiz-logo.svg"); */
  // url("../images/multifamilybiz-logo.svg");
  background-image:url("../images/multifamilybiz-logo-main.png");
  height: 2.3rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 50%;
  transform: translateY(15%);

  @media screen and (max-width: 1023px) {
    width: 17rem;
  }
}

.search {
  &__btn {
    display: inline-block;
    border: none;
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
    transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-left: 2rem;

    i {
      color: #fff;
      font-size: 2rem;
    }

    &:focus {
      outline: none;
    }
  }

  &__bar {
    width: 100vw;
    transition: all .1s ease-in-out;
    display: flex;
    align-items: center;
    transform: scale(0);
    padding:0 13% .3rem 13%;
    background: #f6f6f6;

    &::after {
      content: "";
      position: absolute;
      width: 75vw;
      height: 2px;
      bottom: 10px;
      left: 0;
      position: absolute;
      background: #d6d6d6;
      color: #d6d6d6;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
    }

    &--open {
      transform: scale(1);
      transform-origin: left;
      position: relative;
      -webkit-box-shadow: 9px 6px 56px -12px rgba(0,0,0,0.67);
      -moz-box-shadow: 9px 6px 56px -12px rgba(0,0,0,0.67);
      box-shadow: 9px 6px 56px -12px rgba(0,0,0,0.67);
    }

    input {
      width: 100%;
      border: none;
      font-size: 5rem;
      background: #f6f6f6;
      color:  rgb(158, 161, 168);
      position: relative;
      font-weight: 300;
      font-family: $condensed-font;

      &:-webkit-input-placeholder {
        color:  rgb(158, 161, 168);
        font-weight: 300;
        font-family: $condensed-font;
      }
      &::-moz-placeholder {
        color:  rgb(158, 161, 168);
        font-weight: 300;
        font-family: $condensed-font;
      }
      &:-ms-input-placeholder {
        color:  rgb(158, 161, 168);
        font-weight: 300;
        font-family: $condensed-font;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color:  rgb(158, 161, 168);
        font-weight: 300;
        font-family: $condensed-font;
      }
      &::placeholder {
        color:  rgb(158, 161, 168);
        font-weight: 300;
        font-family: $condensed-font;
      }
      &:focus {
        outline: none;    
        color:  rgb(158, 161, 168);
        font-weight: 300;
        font-family: $condensed-font;   
      }
    }
  }

  &--close  {
    font-size: 4rem;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      transform: scale(1.3);
      color: #818181;
    }
      i {
        color: #d6d6d6;
      }
    }

  &__wrapper {
    position: fixed;
    top: 5rem;
    z-index: 4;
  }
}

.nav a:hover {
  color: rgb(170, 170, 170);
}

.navbar-dark {
  span {
    font-size: 1.6rem;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $color-white;
}

.navbar-dark .navbar-nav>li>a:hover {
  color: $color-medium-grey;
}

.fa-caret-down {
  padding-right: .3rem;
}

.dropdown-item.sign-in {
  padding: 1rem 4rem;
  &:focus {
    color: $color-dark-text;
  }
  .fa-sign-in {
    margin-right: .5rem;
  }

  .fa-user-circle {
    margin-right: .5rem;
  }
}
