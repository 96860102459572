// Weather
@font-face {
    font-family: 'forecast-font';
    src: url("/fonts/forecast-font-1.2/forecast-font.eot");
    src: url("/fonts/forecast-font-1.2/forecast-font.eot?#iefix") format('embedded-opentype'), url("/fonts/forecast-font-1.2/forecast-font.woff") format('woff'), url("/fonts/forecast-font-1.2/forecast-font.ttf") format('truetype'), url("/fonts/forecast-font-1.2/forecast-font.svg#iconvault") format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon-night:after,
.icon-sunny:after,
.icon-frosty:before,
.icon-windysnow:before,
.icon-showers:before,
.icon-basecloud:before,
.icon-cloud:after,
.icon-rainy:before,
.icon-mist:before,
.icon-windysnowcloud:before,
.icon-drizzle:before,
.icon-snowy:before,
.icon-sleet:before,
.icon-moon:after,
.icon-windyrain:before,
.icon-hail:before,
.icon-sunset:before,
.icon-windyraincloud:before,
.icon-sunrise:before,
.icon-sun:after,
.icon-thunder:before,
.icon-basethundercloud:before,
.icon-windy:before {
    font-family: 'forecast-font';
    //font-size: 4em;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    position: absolute;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-decoration: inherit;

    // FIXES OLD IE em font-size on psuedo elements
    font-size: 72px;
    @media (min-width: 778px) {
        font-size: 72px;
    }
}

.icon-night:after {
    content: "\f100";
}

.icon-sunny:after {
    content: "\f101";
}

.icon-frosty:before {
    content: "\f102";
}

.icon-windysnow:before {
    content: "\f103";
}

.icon-showers:before {
    content: "\f104";
}

.icon-basecloud:before {
    content: "\f105";
}

.icon-cloud:after {
    content: "\f106";
}

.icon-rainy:before {
    content: "\f107";
}

.icon-mist:before {
    content: "\f108";
}

.icon-windysnowcloud:before {
    content: "\f109";
}

.icon-drizzle:before {
    content: "\f10a";
}

.icon-snowy:before {
    content: "\f10b";
}

.icon-sleet:before {
    content: "\f10c";
}

.icon-moon:after {
    content: "\f10d";
}

.icon-windyrain:before {
    content: "\f10e";
}

.icon-hail:before {
    content: "\f10f";
}

.icon-sunset:before {
    content: "\f110";
}

.icon-windyraincloud:before {
    content: "\f111";
}

.icon-sunrise:before {
    content: "\f112";
}

.icon-sun:after {
    content: "\f113";
}

.icon-thunder:before {
    content: "\f114";
}

.icon-basethundercloud:before {
    content: '\f105';
}

.icon-windy:before {
    content: "\f115";
}

.icon-sunrise:before,
.icon-sunset:before {
    color: #707070;
}

.icon-sun:after,
.icon-moon:after,
.icon-sunny:after,
.icon-night:after,
.icon-thunder:before {
    color: #707070;
}

.icon-cloud:after,
.icon-mist:before .icon-basecloud:before,
.icon-windy:before,
.icon-windyraincloud:before,
.icon-windysnowcloud:before,
.icon-hail:before {
    color: #707070;
}

.icon-basethundercloud:before {
    color: #707070;
}


.icon-drizzle:before,
.icon-showers::before {
    color: #707070;
}

.icon-rainy:before {
    color: #707070;
}

.icon-windyrain:before,
.icon-snowy:before,
.icon-windysnow::before,
.icon-sleet:before {
    color: #707070;
}

.icon-frosty:before {
    color: #707070;
}

#weather {
    .location {
        .time {
            display: block;
            font-size: 1.5em;
            font-weight: 200;
            line-height: 1;
            margin-top: 3rem;
        }

        .date {
            font-size: 80%;
        }
    }

    .conditions {
        min-height: 100px;

        .current {
            .icon {
                height: 4em;
                width: 4em;
            }

            .temperature {
                font-weight: 200;
                font-size: 1.5em;
                line-height: 1;
            }

            .units {
                font-size: 75%;
            }
        }

        ul {
            margin-bottom: 0;

            .high {
                i {
                    color: $color-dark-text;
                }
            }

            .low {
                i {
                    color: $color-darker-grey;
                }
            }
        }
    }

    .branding {
        font-size: 60%;
    }
}

.temperature {
    font-size: 7rem;
    font-weight: 600;
    padding-bottom: 0;
    color: #5E5D5D;
}
