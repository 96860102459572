/* ABOUT US PAGE  */

/*   ABOUT US TYPOGRAPHY */

.intro {
    text-align: center;

    .col {
        padding-left: 0;
        padding-right: 0;
    }

    .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    @media screen and (max-width: 576px) {
        text-align: left;
    }
    h1 {
        font-size: 3.75em;
        color: $color-dark-text;
        font-family: $condensed-font;
        font-weight: 400;
        margin-bottom: 1rem;

        @media screen and (max-width: 848px) {
            font-size: 3.2rem;
        }

        @include respond (phone) {
            font-size: 2.6rem;
        }
    }
    p {
        line-height: 2;
        margin: 0 auto;
        color: #555;
        font-family: $default-font;
        font-size: 2rem;
        max-width: 94rem;
        @media screen and (max-width: 848px) {
            font-size: 1.6rem;
        }
    }
}

.features {
    padding: 8rem 0;
    text-align: center;
    .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    @media screen and (max-width: 848px) {
        padding: 4rem 0 0 0;
    }
    h1 {
        font-size: 4.9em;
        font-family: $condensed-font;
        font-weight: 400;
        color: $color-dark-text;
        @media screen and (max-width: 848px) {
            font-size: 3.6rem;
        }
        &:after {
            content: "\002B";
            font-size: 0.65em;
            font-family: 'Roboto thin', sans-serif;
            display: inline-block;
            transform: translateY(-.13em);
            margin-left: 5px;
        }
    }
    h2 {
        font-size: 2rem;
        line-height: 1.5;
        color: #2E3137;
        text-transform: uppercase;
        font-family: $condensed-font;
    }
    p {
        max-width: 30rem;
        margin: auto;
        @media screen and (max-width: 848px) {
            margin-bottom: 3em;
        }
    }
    &--stats {
        h1 {
            font-family: $condensed-font;
            font-size: 3.75em;
            margin-bottom: 4rem;
            font-weight: 400;
            @media screen and (max-width: 848px) {
                font-size: 3.2rem;
                margin-bottom: 1rem;
            }

            @include respond (phone) {
                font-size: 2.6rem;
            }
                &::after {
                    display: none;
                }
            }
            
        i {
            font-size: 4.9em;
            margin: 1rem;
        }
    }
}

.industry-focused {
    background-color: $color-light-1;
    max-width: 100%;
    padding: 15rem 0;
    text-align: center;
    @media screen and (max-width: 848px) {
        padding: 8rem 0;
        margin: auto;
    }
    h1 {
        font-size: 3.75em;
        color: $color-dark-text;
        font-family: $condensed-font;
        font-weight: 400;
        @media screen and (max-width: 848px) {
            font-size: 3.0rem;
        }
    }
    h3 {
        font-size: 4rem;
        max-width: 101rem;
        line-height: 1.5em;
        margin: 1.5rem auto auto;
        font-weight: 300;
        padding: 0 2rem;
        @media screen and (max-width: 848px) {
            font-size: 1.8rem;
        }
    }
    p {
        font-size: 1.8rem;
        line-height: 2;
    }
}

.connecting {
    padding: 8rem 0;
    @media screen and (max-width: 848px) {
        padding: 4rem 0;
    }
}

.changingthegame {
    padding: 6rem 0;
    text-align: center;
    @media screen and (max-width: 848px) {
        padding: 4rem 2rem 0rem 2rem;
        text-align: left;
    }
    h1 {
        font-family: $condensed-font;
        font-size: 3.75em;
        font-weight: 400;
        margin-bottom: 2rem;
        @media screen and (max-width: 848px) {
            font-size: 3.6rem;
        }
    }
    p {
        max-width: 101rem;
        font-size: 2rem;
        margin: auto;
        font-family: 1.8rem;
        font-weight: 300;
        line-height: 2;
        @media screen and (max-width: 848px) {
            font-size: 1.6rem;
            margin-bottom: 4rem;
        }
    }
}

@media screen and (max-width:860px) {
    .title-box {
        max-height: 10rem;
    }
}

@media screen and (max-width:848px) {
    .side-wrapper {
        display: none;
    }
    .apt-hero-container {
        padding-right: 0;
    }
}