.policy {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    padding: 1rem 0 12rem 0;
    @include respond(images3) {
        justify-content: baseline;
        flex-flow: column;
        padding: 0;
    }
    
    h2 {
        font-size: 3.5rem;
        margin-bottom: 3rem;
    }
    p {
        font-size: 1.6rem;
    }
    img {
        width: 3.5rem;
        margin-right: 2rem;
        margin-bottom: .7rem;
    }

    &__policy {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 0 0 49%;
        padding: 7rem;
        height: 40rem;
        transition: .2s;
        border: 1px solid #e6e6e6;
        -webkit-filter: grayscale(90%); 
        filter: grayscale(90%);
        text-align: center;

        .privacy-head {
            img {
                width: 12rem;
            }
        }
        @include respond(images3) {
            flex: auto;
            margin-bottom: 2rem;
        }
        @include respond(phone) {
            padding: 3rem;
        }
    }

    &__policy:hover {
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, .1);

        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);
    }
    &__btn {
        display: inline-block;
        padding: 1.2rem 2rem;
        text-align: center;
        margin-top: 4rem;
        transition: .2s;
        text-transform: uppercase;
        align-self: center;
        margin-right: 3rem;
        border: 1px solid #e6e6e6;
        width: 40%;
        @include respond(images3) {
            padding: 2rem;
            width: 100%;
            padding: 1.2rem 1rem;
            align-self: center;
            margin-right: 0rem;
        }
    }
    &__btn:hover {
        color: #fff;
        background-color: $color-primary;
    }
}