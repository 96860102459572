.elastic-search .wrap-search{
    margin-left: 0rem !important;
    padding-right: 5rem;
    flex: 1 1 70%;
    padding-bottom: 1em;
  }
  
  .elastic-search .wrap-search p{
    padding-top: 13px;
    padding-bottom: 12px;
    text-align: left;
  }
  .elastic-search .article-content{
    margin-left: 0rem;
  }
  .elastic-search .title{
    font-size: 15px;  
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .elastic-search .news-line-d{
    margin: 1.5rem 0;
  }
  
  .elastic-search .news-head{
    margin-bottom: 2rem;
  }
  .elastic-search .search__btn {
    margin-left: 0rem !important;
    background: #F6F6F6;
  }
  
  .elastic-search .search__btn i {
    color: #B1B1B1;
    font-size: 1.5rem;
    margin-left: 0rem !important;
  }
  
  .elastic-search .input-group-append{
    border-top: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
  }
  
  .elastic-search span{
    padding: 0px 28px 0px 2px;
  }
  
  .elastic-search .news-article__link {
    display: block;
    position: relative;
    overflow: hidden;
    flex: 0 0 14.4rem;
    height: 9.2rem;
    padding-right: 1em;
  }
  .elastic-search .videos{
    padding-top: 0em;
  }
  .elastic-search .dateCreated{
    font-size: 12px;
    float: right;
    position: relative;
    padding-right: 0px;
  }
  
  @media (max-width: 68em){
    .elastic-search .flex-wrap-news {
        flex: 1 1 100%;
        margin-bottom: 2rem;
    }
  
    .elastic-search .article-content__text, .article-content-blog__text {
      text-align: left;
      display: block;
    }  
  
    .elastic-search .title {
      text-align: left;
      line-height: 3em;
    }  
  
    .elastic-search .wrap-search p {
      padding-top: 24px;
    }  
  }