
.img-responsive,
.img-responsive.ad {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  height:100%;
  background-repeat: no-repeat;

}

.img-responsive:hover,
.img-responsive.ad:hover {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transform: scale(1.1);

}

.img-responsive:hover {
  opacity: .69;
}

.img-responsive-ad {
  height: 19.2rem;
  flex: 1 1 30%;
  transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  overflow: hidden;
}

.img-responsive-ad img {
  height: 19.2rem;
  flex: 1 1 auto;
  transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  
}

.img-responsive-ad img:hover {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.img-responsive-ad:hover {
  opacity: .9;
}


.img-box-fx:hover {
  background-color: rgba(0, 0, 0, 5);
}

.content-img {
  height: 19.2rem;
  width: 27.2rem;
  background-position: center center;
  overflow: hidden;
}

