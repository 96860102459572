/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ui-slider {
	position: relative;
	text-align: left;
}
.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 1.2em;
	height: 1.2em;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}

.ui-slider-horizontal {
	height: .5em;
	background: $color-medium-grey;

	.ui-slider-handle {
		top: -.3em;
		margin-left: -.6em;
		background-color: $color-primary;
	}
	.ui-slider-range {
		top: 0;
		height: 100%;
		background-color: $color-darker-grey;
	}
	.ui-slider-range-min {
		left: 0;
	}
	.ui-slider-range-max {
		right: 0;
	}
}

.ui-slider-vertical {
	width: .8em;
	height: 100px;
	background: $color-medium-grey;

	.ui-slider-handle {
		left: -.3em;
		margin-left: 0;
		margin-bottom: -.6em;
		background-color: $color-primary;
	}
	.ui-slider-range {
		left: 0;
		width: 100%;
		background-color: $color-darker-grey;
	}
	.ui-slider-range-min {
		bottom: 0;
	}
	.ui-slider-range-max {
		top: 0;
	}
}